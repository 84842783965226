import React, { useContext, useState } from "react";
import { validateEmail } from "../../utils";
import { ModalContext } from "../../context/ModalContext";

const InviteUsers = ({ handleCancel, handleSubmitUser }) => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("member");
  const { alert } = useContext(ModalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      return alert("El email no es válido.");
    }
    handleSubmitUser({ email, role });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Escribe el correo que quieres invitar</label>
      <label className="small mb-1">
        Separa con comas para invitar varios usuarios
      </label>
      <input
        type="text"
        value={email}
        className="form-control mb-3"
        onChange={(e) => setEmail(e.target.value)}
      />
      <select
        value={role}
        className="form-control mb-3"
        onChange={(e) => setRole(e.target.value)}
      >
        <option value="owner">Propietario</option>
        <option value="admin">Administrador</option>
        <option value="member">Miembro</option>
      </select>
      <button className="btn btn-primary w-100 my-3">Invitar</button>
      <button
        type="button"
        className="btn btn-link w-100 text-secondary"
        onClick={handleCancel}
      >
        Cancelar
      </button>
    </form>
  );
};

export default InviteUsers;
