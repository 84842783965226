import { Link } from "@reach/router";
import React from "react";

const UserData = ({ user }) => {
  return (
    <div className="card text-center mb-4">
      <h2>
        {user.name} {user.last_name}
      </h2>
      <p className="text-muted mb-2">
        <i className="fa fa-envelope me-2"></i>
        {user.email}
      </p>
      <p className="text-muted">
        <i className="fa fa-phone me-2"></i> {user.phone}
      </p>
      <Link to="/profile/edit" className="btn btn-primary m-auto d-block w-100">
        Edit Profile
      </Link>
    </div>
  );
};

export default UserData;
