import React, { useEffect } from "react";
import { useContext } from "react";
import { navigate } from "@reach/router";
import SelectVehicle from "../components/vehicles/SelectVehicle";
import { MerchantContext } from "../context/MerchantContext";
import { VehiclesContext } from "../context/VehiclesContext";
import ProductRow from "../components/products/ProductRow";
import { ModalContext } from "../context/ModalContext";
import MerchantDetails from "../components/merchants/MerchantDetails";

const SingleMerchant = ({ merchant_id }) => {
  const { vehicles, getVehicles } = useContext(VehiclesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { merchant, getSingleMerchant } = useContext(MerchantContext);

  const handleSubmit = (selected) => {
    const current = vehicles.find(
      (vehicle) => parseInt(vehicle.vehicle_id) === parseInt(selected)
    );
    if (current) {
      navigate(`/ticket/${merchant_id}/vehicle/${current.license_plate}`);
    }
  };

  useEffect(() => {
    getVehicles();
    getSingleMerchant(merchant_id);
  }, []);

  useEffect(() => {
    if (merchant && merchant !== null) {
      getVehicles();
    }
  }, [merchant]);

  const handleVehicles = () => {
    modalComponent(
      "Elegir un Vehículo",
      <SelectVehicle
        vehicles={vehicles}
        handleCancel={clearModal}
        handleSubmit={handleSubmit}
        title="¿Con qué vehículo entrarás?"
      />
    );
  };

  const renderMerchant = () => {
    if (merchant && merchant !== null) {
      return <MerchantDetails merchant={merchant} />;
    }
  };

  const renderProducts = () => {
    if (merchant && merchant !== null) {
      const { products } = merchant;
      if (Array.isArray(products)) {
        if (products.length === 0) {
          return <p>No hay tarifas para este comercio.</p>;
        }
        return products.map((product) => (
          <ProductRow key={product.product_id} product={product} />
        ));
      }
    }
  };

  return (
    <div className="container-fluid pt-4">
      {renderMerchant()}
      <div className="mt-4">
        <h2 className="text-center">Tarifas</h2>
        <div className="card pt-2">{renderProducts()}</div>
      </div>
      <div className="fixed-bottom container mb-3">
        <button
          className="btn btn-primary w-100"
          disabled={!Array.isArray(vehicles)}
          onClick={handleVehicles}
        >
          Entrar al Comercio
        </button>
      </div>
    </div>
  );
};

export default SingleMerchant;
