import React, { useEffect, useContext } from "react";
import { WalletsContext } from "../context/WalletsContext";

import WalletList from "../components/wallet/WalletList";
import { MenuContext } from "../context/MenuContext";

const Wallets = () => {
  const { wallets, getWallets } = useContext(WalletsContext);
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    getWallets();
    setSelected("My Wallets");
  }, []);

  return (
    <div className="container">
      <WalletList wallets={wallets} />
    </div>
  );
};

export default Wallets;
