import api from "./api";

const route = "/vehicles";

const VehiclesService = {
  getVehicles: () => api.get(route),
  getSingleVehicle: (vehicle_id) => api.get(`${route}/${vehicle_id}`),
  postVehicle: (vehicle) => api.post(route, { ...vehicle }),
  putVehicle: (vehicle) =>
    api.put(`${route}/${vehicle.vehicle_id}`, { ...vehicle }),
  deleteVehicle: (vehicle_id) => api.delete(`${route}/${vehicle_id}`),
};
export default VehiclesService;
