import React from "react";
import { formatMonto } from "../../utils";
import { parsePeriod } from "../../utils/tickets";

const ProductRow = ({ product }) => {
  const renderPrice = () => {
    if (product.price === null || parseFloat(product.price) === 0.0) {
      return <span className="text-success bold">Gratis</span>;
    }
    return `$${formatMonto(product.price)}`;
  };
  return (
    <div className="row border-bottom py-2">
      <div className="col-6">{renderPrice()}</div>
      <div className="col-6 text-end">
        {product.limit_per_order === null ? "por" : product.limit_per_order}{" "}
        {parsePeriod(product.limit_per_order, product.usage_period)}
      </div>
    </div>
  );
};

export default ProductRow;
