import React, { useState, useContext, useEffect } from "react";
import TicketCard from "../components/tickets/TicketCard";
import { TicketsContext } from "../context/TicketsContext";
import { MenuContext } from "../context/MenuContext";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";

const Tickets = () => {
  const [status, setStatus] = useState("all");
  const { tickets, getMyTickets } = useContext(TicketsContext);
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    getMyTickets();
    setSelected("My Tickets");
  }, []);

  const renderTickets = () => {
    if (Array.isArray(tickets)) {
      let ticketsRender = [...tickets];
      if (status !== "all") {
        ticketsRender = ticketsRender.filter(
          (ticket) => ticket.status === status
        );
      }
      if (ticketsRender.length === 0) {
        return <p>You don't have any tickets.</p>;
      }
      return ticketsRender.map((ticket) => (
        <TicketCard key={ticket.ticket_id} ticket={ticket} />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid py-3">
      <IonSegment value={status} className="mb-3">
        <IonSegmentButton value="all" onClick={() => setStatus("all")}>
          <IonLabel>All</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="active" onClick={() => setStatus("active")}>
          <IonLabel>Active</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="pending" onClick={() => setStatus("pending")}>
          <IonLabel>Pending</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="paid" onClick={() => setStatus("paid")}>
          <IonLabel>Paid</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <div className="my-3">{renderTickets()}</div>
    </div>
  );
};

export default Tickets;
