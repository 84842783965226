import { formatMonto } from "../../utils";
import StatusBadge from "../global/StatusBadge";
import { Link } from "@reach/router";
import moment from "moment";
import React from "react";

const ChargeCard = ({ charge }) => {
  return (
    <Link
      to={`/charge/${charge.charge_id}`}
      className="card p-3 mb-2 shadow-sm text-dark no-decoration"
    >
      <div className="row">
        <div className="col-10">
          <div className="row">
            <div className="col-6">
              <h3>{charge.merchant.name}</h3>
              <p className="mb-0">${formatMonto(charge.amount)}</p>
            </div>
            <div className="col-6">
              <p className="mb-2">
                {moment(charge.createdAt).format("DD MMM YYYY HH:mm")}
              </p>
              <StatusBadge status={charge.status} />
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <button className="btn btn-sm btn-outline-secondary">
            <i className="fa fa-info-circle"></i>
          </button>
        </div>
      </div>
    </Link>
  );
};

export default ChargeCard;
