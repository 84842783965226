import React, { useContext, useEffect, useState } from "react";
import { TicketsContext } from "../context/TicketsContext";
import { io } from "socket.io-client";

const AttemptTicket = ({ merchant_id, license_plate }) => {
  const [socket, setSocket] = useState(null);
  const [status, setStatus] = useState("");

  const { ticket, attemptTicket } = useContext(TicketsContext);

  useEffect(() => {
    if (ticket === null) {
      attemptTicket(merchant_id, license_plate);
      let currentSocket = io("https://wallgoapp.com", {
        transports: ["websocket"],
      });
      setSocket(currentSocket);
    }
  }, []);

  useEffect(() => {
    if (socket !== null) {
      setupSocket();
    }
  }, [socket]);

  const setupSocket = () => {
    socket.on("connection", () => {
      setStatus("Solicitando acceso con WallGo");
    });
    socket.on("status", (message) => {
      setStatus(message);
    });
  };

  const renderTicket = () => {
    if (ticket !== undefined && ticket !== null) {
      if (typeof ticket === "object") {
        return (
          <div style={{ marginTop: -100 }} className="container text-center">
            <i className="fa fa-3x fa-circle-check text-success mb-3"></i>
            <h1>Acceso Otorgado</h1>
          </div>
        );
      }
      return (
        <div style={{ marginTop: -100 }} className="container text-center">
          <i className="fa fa-3x fa-circle-xmark text-danger mb-3"></i>
          <h1>Acceso Denegado</h1>
          <p>Por favor, toma un boleto regular.</p>
        </div>
      );
    }
    return (
      <div style={{ marginTop: -100 }} className="container text-center">
        <h3>{status}</h3>
        <div className="spinner-border"></div>
      </div>
    );
  };

  return <div className="row vh-100 align-items-center">{renderTicket()}</div>;
};

export default AttemptTicket;
