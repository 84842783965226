import api from "./api";

const route = "/users";

const userService = {
  getCurrentUser: () => api.get(route),
  postUser: (data) => api.post(route, { ...data }),
  postSignUp: (email) => api.post(`${route}/signup`, { email }),
  updateCurrentUser: (data) => api.put(route, { ...data }),
  recoverPassword: (email) => api.post(`${route}/recover`, { email }),
  deleteCurrentUser: () => api.delete(route),
};

export default userService;
