import React, { useReducer, createContext } from "react";
import TicketReducer from "../reducers/TicketsReducer";
import TicketService from "../services/TicketsService";
import {
  TICKETS_RECEIVED,
  TICKET_DENIED,
  TICKET_GRANTED,
} from "../types/tickets";

const initialState = {
  ticket: null,
};
export const TicketsContext = createContext(initialState);

export const TicketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TicketReducer, initialState);

  const getMyTickets = (filters) => {
    TicketService.getMyTickets(filters).then((res) => {
      const { tickets } = res.data;
      dispatch({ type: TICKETS_RECEIVED, payload: tickets });
    });
  };

  const attemptTicket = (merchant_id, license_plate) => {
    TicketService.attemptTicket(merchant_id, license_plate)
      .then((res) => {
        const { ticket } = res.data;
        dispatch({ type: TICKET_GRANTED, payload: ticket });
      })
      .catch((error) => {
        dispatch({ type: TICKET_DENIED });
      });
  };

  return (
    <TicketsContext.Provider value={{ ...state, attemptTicket, getMyTickets }}>
      {children}
    </TicketsContext.Provider>
  );
};
