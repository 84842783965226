import React, { useContext } from "react";
import moment from "moment";
import WalletCard from "./WalletCard";
import WalletForm from "./WalletForm";
import { ModalContext } from "../../context/ModalContext";

const WalletList = ({ wallets }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleCreate = () => {
    modalComponent("Add Wallet", <WalletForm handleCancel={clearModal} />);
  };
  const renderWallets = () => {
    if (Array.isArray(wallets)) {
      wallets = wallets.sort((a, b) => (moment(a).isAfter(moment(b)) ? -1 : 1));
      if (wallets.length === 0) {
        return <p className="px-1">No wallets found.</p>;
      }
      return wallets.map((wallet) => (
        <WalletCard hideButton key={wallet.wallet_id} wallet={wallet} />
      ));
    }
    return <div className="spinner-border"></div>;
  };
  return (
    <div className="container-fluid">
      {renderWallets()}
      <div className="fixed-bottom p-3 w-100">
        <button onClick={handleCreate} className="btn w-100 btn-primary">
          + Add Wallet
        </button>
      </div>
    </div>
  );
};

export default WalletList;
