import React, { useContext, useEffect } from "react";
import { PaymentSourcesContext } from "../context/PaymentSourceContext";
import PaymentSourceCard from "../components/payment_sources/PaymentSourceCard";
import PaymentSourceForm from "../components/payment_sources/PaymentSourceForm";
import { ModalContext } from "../context/ModalContext";
import ConfirmDeletePaymentSource from "../components/payment_sources/ConfirmDeletePaymentSource";

const PaymentSources = () => {
  const { payment_sources, getPaymentSources, deletePaymentSource } =
    useContext(PaymentSourcesContext);

  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getPaymentSources();
  }, []);

  const handleCreate = () => {
    modalComponent(
      "Agregar Método",
      <PaymentSourceForm handleCancel={clearModal} />
    );
  };

  const handleDelete = (payment_source) => {
    modalComponent(
      "Precaución",
      <ConfirmDeletePaymentSource
        payment_source={payment_source}
        onSuccess={deletePaymentSource}
        onCancel={clearModal}
      />
    );
  };

  const renderMetodosPago = () => {
    if (Array.isArray(payment_sources)) {
      if (payment_sources.length === 0) {
        return <p>No hay payment_sources de pago registrados</p>;
      }
      return payment_sources.map((payment_source) => (
        <PaymentSourceCard
          hideCheck
          handleDelete={handleDelete}
          payment_source={payment_source}
          key={payment_source.payment_source_id}
        />
      ));
    }
  };

  return (
    <div className="container-fluid pt-4">
      <div className="mx-0 row align-items-center mb-3 pb-2 border-bottom">
        <div className="col-8 ps-0">
          <h2 className="mb-0">My Payment Methods</h2>
        </div>
        <div className="col-4 pe-0 text-end">
          <button className="btn btn-primary bold" onClick={handleCreate}>
            + Agregar
          </button>
        </div>
      </div>
      {renderMetodosPago()}
    </div>
  );
};

export default PaymentSources;
