import { navigate } from "@reach/router";
import React from "react";
import { QrReader } from "react-qr-reader";

const QrCodeWeb = () => {
  const handleResult = (result, error) => {
    if (typeof result === "object") {
      let { text } = result;
      if (text.includes(window.location.host)) {
        text = text.split(window.location.host)[1];
        navigate(text);
      }
      window.open(text, "_self");
    }
  };
  return (
    <div>
      <p className="text-center">Apunta la cámara al código QR</p>
      <QrReader
        onResult={handleResult}
        constraints={{
          facingMode: "environment",
        }}
        style={{ width: "100%", height: "80vh" }}
        videoContainerStyle={{ width: "100%", height: "80vh" }}
      />
    </div>
  );
};

export default QrCodeWeb;
