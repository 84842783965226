import React from "react";
import { useLoadScript } from "@react-google-maps/api";
import { GOOGLE_MAPS_KEY } from "../../utils";

const GoogleMapsWrapper = ({ children }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    libraries: ["places"],
  });

  if (!isLoaded) return <div className="spinner-border"></div>;

  return <div>{children}</div>;
};

export default GoogleMapsWrapper;
