import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import { hasNotch } from "../../utils";

const ErrorAlert = () => {
  const { device } = useContext(CapacitorContext);
  const { showAlert, alertContent, hideAlert } = useContext(ModalContext);
  return (
    <div
      className={`alert alert-danger alert-dismissible fixed-top ms-auto me-2 mt-1 fade-show ${
        !showAlert ? "hidden" : ""
      }`}
      role="alert"
      style={{
        maxWidth: 500,
        zIndex: 2500,
        marginTop: hasNotch(device) ? 64 : "inherit",
      }}
    >
      {alertContent}
      <button className="btn-close" onClick={hideAlert}></button>
    </div>
  );
};

export default ErrorAlert;
