import React, { useContext, useEffect } from "react";
import VehicleForm from "../components/vehicles/VehicleForm";
import { VehiclesContext } from "../context/VehiclesContext";
import { MenuContext } from "../context/MenuContext";
import { navigate } from "@reach/router";

const SingleVehicle = ({ vehicle_id }) => {
  const { setSelected } = useContext(MenuContext);
  const { vehicle, createVehicle, getSingleVehicle, clearSingleVehicle } =
    useContext(VehiclesContext);

  useEffect(() => {
    if (isNaN(parseInt(vehicle_id))) {
      setSelected("Add Vehicle");
      createVehicle();
    } else {
      setSelected("Edit Vehicle");
      getSingleVehicle(vehicle_id);
    }
    return clearSingleVehicle;
  }, [vehicle_id]);

  const handleCancel = () => {
    navigate(`/vehicles`);
  };

  const renderVehicle = () => {
    if (vehicle && vehicle !== null) {
      return <VehicleForm handleCancel={handleCancel} />;
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="container">{renderVehicle()}</div>;
};

export default SingleVehicle;
