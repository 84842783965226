import api from "./api";
import firebase from "firebase";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAIMedav809aF0IjyC03_nhkffR3o4Tyto",
  authDomain: "app-wallgo.firebaseapp.com",
  projectId: "app-wallgo",
  storageBucket: "app-wallgo.appspot.com",
  messagingSenderId: "697046717906",
  appId: "1:697046717906:web:e21b00d2ca4a02a195e40b",
  measurementId: "G-GLTYVKQ15R",
};

// Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const getToken = () => auth.currentUser.getIdToken(true);

const auth = firebase.auth();

const handleSignInError = (error) => {
  throw error;
};

const AuthService = {
  signIn: (email, password) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        return getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          return user;
        });
      })
      .catch((error) => handleSignInError(error, email, password)),
  userLoggedIn: (success, error) =>
    auth.onAuthStateChanged((user) => {
      if (user) {
        getToken().then((token) => {
          api.defaults.headers.common["Authorization"] = token;
          if (success) success(user);
        });
      } else {
        error();
      }
    }),
  signOut: () => auth.signOut(),
  signUp: (correo, password) =>
    auth.createUserWithEmailAndPassword(correo, password),
  recoverPassword: (email) => auth.sendPasswordResetEmail(email),
  getToken: () => auth.currentUser.getIdToken(true),
  updateEmail: (email) => auth.currentUser.updateEmail(email),
  getRecaptchaVerifier: (button_id, callback) =>
    new firebase.auth.RecaptchaVerifier(button_id, {
      size: "invisible",
      callback,
    }),
  signInWithPhoneNumber: (phone, verifier) =>
    auth.signInWithPhoneNumber(phone, verifier),
  handleToken: () =>
    getToken().then((token) => {
      api.defaults.headers.common["Authorization"] = token;
    }),
  signInWithGoogle: async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return await auth.signInWithPopup(provider);
  },
  setToken: (token) => (api.defaults.headers.common["Authorization"] = token),
};

export default AuthService;
