import React from "react";
import { S3_ENDPOINT } from "../../utils";

const MerchantDetails = ({ size, merchant }) => {
  const getSrc = () => {
    let src = "logo.png";
    if (merchant.file && merchant.file !== null) {
      return `${merchant.file.name}.${merchant.file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };
  const src = getSrc();
  const { name, opening_time, closing_time } = merchant;
  return (
    <div className="card mb-3 text-center p-3">
      <div className="row align-items-center">
        <div className={size === "lg" ? "col-12" : "col-4"}>
          <img
            src={src}
            alt={name}
            style={{ maxWidth: 150 }}
            className="w-100 m-auto d-block mb-3"
          />
        </div>
        <div className={size === "lg" ? "col-12" : "col-8 text-start"}>
          <h2 className="bold h3 mb-2 border-bottom pb-2">{name}</h2>
          <p className="bold mb-1">Horario</p>
          <p className="mb-0">
            {opening_time} - {closing_time}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MerchantDetails;
