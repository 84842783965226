import { IonLabel } from "@ionic/react";
import React from "react";

const IconItem = ({
  icon,
  name,
  isSidebar = false,
  isRegular = false,
  showNotification,
}) => {
  const styleFaRegular = isRegular ? "fa-regular" : "fa";

  const isImage = () => {
    return icon.startsWith("w-");
  };

  const getImageURL = (icon) => {
    if (isImage()) {
      return require(`../../assets/images/${icon}.svg`).default;
    } else return null;
  };

  const Notification = () => (
    <i className="fa-solid fa-circle-exclamation notification" />
  );

  return (
    <div className={isSidebar ? "px-0 mx-0" : "row p-3 align-items-center"}>
      {showNotification && <Notification />}
      <div className={isSidebar ? "" : "col-3 ps-0"}>
        <div className="icon-wrapper mb-3 bg-light border">
          {isImage() ? (
            <img src={getImageURL(icon)} alt="icon" />
          ) : (
            <i className={`${styleFaRegular} fa-${icon}`}></i>
          )}
        </div>
      </div>
      <div className={isSidebar ? "" : "col-9 text-start"}>
        <IonLabel>
          <h4>{name}</h4>
        </IonLabel>
      </div>
    </div>
  );
};
export default IconItem;
