import React, { createContext, useContext, useReducer } from "react";
import AuthService from "../services/AuthService";
import FilesService from "../services/FilesService";
import UserReducer from "../reducers/UserReducer";
import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  LOGIN,
  LOGOUT,
  SET_PROPIEDAD_USER,
  GUARDAR_USUARIO,
  EDITAR_USUARIO,
  SET_CONFIRMATION_RESULT,
  SET_PROPIEDAD_LOGIN,
} from "../types";
import UserService from "../services/UserService";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import { hideModal } from "../utils";
import moment from "moment";

const initialState = {
  user: null,
  correo: null,
  password: null,
  telefono: null,
  cuenta: null,
  direccion: null,
  spinner: false,
  confirmationResult: null,
};

export const AuthContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  function handleError(error) {
    dispatch({ type: HIDE_SPINNER });
    if (error.response) {
      if (error.response.status === 403) {
        return alert("Lo sentimos. No tienes un acceso activo a WallGo.");
      }
      if (error.response.status === 404) {
        return alert(
          "Lo sentimos. Ese usuario no existe en nuestra base de datos."
        );
      }
    }
    alert(error);
  }

  function facebookSignIn(user) {
    AuthService.setToken(user.userID);
    window.localStorage.setItem("facebookUserId", user.userID);
    getCurrentUser();
  }

  function facebookSignUp(user) {
    UserService.postUser({ ...user, provider: "Facebook" })
      .then(() => {
        AuthService.setToken(user.userID);
        getCurrentUser();
      })
      .catch(handleError);
  }

  function signInWithPhoneNumber(phoneNumber, appVerifier) {
    AuthService.signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        dispatch({
          type: SET_CONFIRMATION_RESULT,
          payload: confirmationResult,
        });
      })
      .catch(alert);
  }

  function signInWithGoogle() {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signInWithGoogle()
      .then((user) => {
        if (user && user !== null) {
          AuthService.getToken().then((token) => {
            UserService.setToken(token);
            UserService.getUsuario()
              .then((res) => {
                dispatch({
                  type: LOGIN,
                  payload: res.data.user,
                });
                dispatch({ type: HIDE_SPINNER });
              })
              .catch(handleError);
          });
        } else {
          alert("Contraseña incorrecta. Por favor, intenta de nuevo");
          dispatch({ type: HIDE_SPINNER });
          AuthService.signOut();
        }
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          alert(
            "Lo sentimos. No encontramos una cuenta con ese correo. ¡Regístrate!"
          );
        }
        if (error.code === "auth/wrong-password") {
          alert("Contraseña incorrecta. Por favor, intenta de nuevo");
        } else {
          alert(error);
        }
        dispatch({ type: HIDE_SPINNER });
      });
  }

  function signIn(email, password) {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signIn(email, password)
      .then((user) => {
        if (user) {
          UserService.getCurrentUser()
            .then((res) => {
              let { user } = res.data;
              dispatch({
                type: LOGIN,
                payload: user,
              });
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status !== 400) {
                  AuthService.signOut();
                  //return alert(error.toString());
                }
              }
              //alert(error);
              //AuthService.signOut();
            });
        } else {
          alert(
            "Ya tienes cuenta en WallGo pero la contraseña es incorrecta. Por favor, intenta de nuevo"
          );
          dispatch({ type: HIDE_SPINNER });
          AuthService.signOut();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.code === "auth/user-not-found") {
          return alert(
            "Lo sentimos. No encontramos una cuenta con ese correo. ¡Regístrate!"
          );
        }
        if (error.code === "auth/wrong-password") {
          return alert(
            "La contraseña es incorrecta. Por favor, intenta de nuevo"
          );
        }
        //alert(error);
      });
  }

  function userLoggedIn() {
    dispatch({ type: SHOW_SPINNER });
    AuthService.userLoggedIn(
      () => {
        UserService.getCurrentUser()
          .then((res) => {
            let { user } = res.data;
            dispatch({
              type: LOGIN,
              payload: user,
            });
            dispatch({ type: HIDE_SPINNER });
          })
          .catch((error) => {
            //alert(error);
            dispatch({ type: HIDE_SPINNER });
          });
      },
      (error) => {
        if (error) {
          //alert(error);
          AuthService.signOut();
        }
        dispatch({ type: HIDE_SPINNER });
      }
    );
  }

  function signOut() {
    AuthService.signOut()
      .then(() => {
        dispatch({ type: LOGOUT });
        navigate("/auth");
      })
      .catch((error) => {
        alert(error);
      });
  }

  function signUpPhone(name, phone, uid) {
    UserService.postUser({
      name,
      phone,
      uid,
    }).then(() => {
      AuthService.handleToken().then(() => {
        getCurrentUser();
      });
    });
  }

  function signInPhone() {
    AuthService.handleToken().then(() => {
      getCurrentUser();
    });
  }

  async function signUp(nombre, correo, password, telefono) {
    dispatch({ type: SHOW_SPINNER });
    if (!nombre || nombre === "") return alert("Ingresa tu nombre");
    if (!correo || correo === "") return alert("Ingresa tu correo");
    if (!telefono || telefono === "") return alert("Ingresa tu telefono");
    AuthService.signUp(correo, password)
      .then((user) => {
        const uid = user.user.uid;
        UserService.postUser({
          name: nombre,
          email: correo,
          phone: telefono,
          uid,
        }).then(() => {
          signIn(correo, password);
        });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.response) {
          if (error.response.status === 412) {
            return alert(
              "Ya tienes una cuenta en WallGo. La contraseña que ingresaste es incorrecta."
            );
          }
        }
        if (error.message === "EMAIL_EXISTS") {
          return alert(
            "Ya existe una cuenta con ese correo electrónico, inicia sesión."
          );
        }
        alert(error.toString());
      });
  }

  function getCurrentUser() {
    UserService.getCurrentUser().then((res) => {
      const { customer } = res.data;
      dispatch({ type: LOGIN, payload: customer });
    });
  }

  function editarUsuario() {
    dispatch({ type: EDITAR_USUARIO });
  }

  function cancelEdit() {
    dispatch({ type: GUARDAR_USUARIO });
  }

  function setPropiedadUser(key, value) {
    if (key === "idFile") {
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key: "file", value } });
      if (!value) {
        dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
      }
    } else {
      if (key === "telefono") {
        value = String(value).replace(/\D/g, "");
        value = String(value).substring(0, 10);
      }
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
    }
  }

  function recoverPassword(email) {
    AuthService.recoverPassword(email)
      .then(() => {
        success("Te hemos enviado un correo para reestablecer tu contraseña.");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          UserService.recoverPassword(email).then(() => {
            success(
              "¡Te hemos enviado un correo para reestablecer tu contraseña!"
            );
          });
        } else {
          alert("Hubo un error al enviar el correo. Inténtalo más tarde.");
        }
      });
  }

  function updateUsuario(usuario) {
    const promises = [];
    let data = { ...usuario };
    delete data.purchases;
    delete data.workouts;
    if (data.picture && data.picture !== null) {
      if (data.picture.name) {
        const promiseFile = new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", data.picture);
          FilesService.postFile(formData).then((res) => {
            const { file_id } = res.data;
            data.file_id = file_id;
            resolve();
          });
        });
        promises.push(promiseFile);
      }
    }
    Promise.all(promises).then(() => {
      delete data.file;
      delete data.uid;
      delete data.activo;
      data.birthday = moment(data.birthday).format("YYYY-MM-DD");
      UserService.updateCurrentUser(data)
        .then(() => {
          getCurrentUser();
          dispatch({ type: GUARDAR_USUARIO });
          success("Perfil actualizado con éxito.");
        })
        .catch((error) => {
          alert(error);
        });
    });
  }

  function deleteCustomer() {
    dispatch({ type: SHOW_SPINNER });
    UserService.deleteCurrentUser()
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        success("Cuenta eliminada.");
        AuthService.signOut();
        navigate("/");
        hideModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  }

  function postSignUp(email) {
    UserService.postSignUp(email)
      .then(() => {
        navigate("/auth/signup");
        dispatch({
          type: SET_PROPIEDAD_LOGIN,
          payload: { key: "correo", value: email },
        });
      })
      .catch(() => {
        navigate("/auth");
        dispatch({
          type: SET_PROPIEDAD_LOGIN,
          payload: { key: "correo", value: email },
        });
      });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        postSignUp,
        cancelEdit,
        signUpPhone,
        signInPhone,
        userLoggedIn,
        updateUsuario,
        editarUsuario,
        getCurrentUser,
        deleteCustomer,
        facebookSignIn,
        facebookSignUp,
        recoverPassword,
        setPropiedadUser,
        signInWithGoogle,
        signInWithPhoneNumber,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
