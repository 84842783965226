import React from "react";
import Profile from "./Profile";
import { Router } from "@reach/router";
import EditProfile from "./EditProfile";
import Dashboard from "./Dashboard";
import PaymentSources from "./PaymentSources";
import QrCodeWeb from "./QrCodeWeb";
import SingleMerchant from "./SingleMerchant";
import Navbar from "../components/navbar/Navbar";
import AttemptTicket from "./AttemptTicket";
import Charges from "./Charges";
import SingleCharge from "./SingleCharge";
import SingleWallet from "./SingleWallet";
import Tickets from "./Tickets";
import Vehicles from "./Vehicles";
import SingleVehicle from "./SingleVehicle";
import Wallets from "./Wallets";

const Panel = () => {
  return (
    <div className="container px-0">
      <Navbar path="/*" />
      <div id="main" className="px-0">
        <Router>
          <Dashboard path="/" default />
          <Wallets path="/wallets" />
          <Profile path="/profile" />
          <Charges path="/charges" />
          <Tickets path="/tickets" />
          <QrCodeWeb path="/qrcode" />
          <Vehicles path="/vehicles" />
          <EditProfile path="/profile/edit" />
          <SingleWallet path="/wallet/:wallet_id" />
          <SingleCharge path="/charge/:charge_id" />
          <PaymentSources path="/payment_sources" />
          <SingleVehicle path="/vehicles/:vehicle_id" />
          <SingleMerchant path="/merchant/:merchant_id" />
          <AttemptTicket path="/ticket/:merchant_id/vehicle/:license_plate" />
        </Router>
      </div>
    </div>
  );
};

export default Panel;
