import React, { useRef, useContext, useEffect } from "react";
import { MerchantContext } from "../context/MerchantContext";
import MerchantCard from "../components/merchants/MerchantCard";

const MerchantList = ({ setMerchant }) => {
  const containerRef = useRef(null);
  const { merchants, getMerchants } = useContext(MerchantContext);

  useEffect(() => {
    getMerchants();
  }, []);

  const getWidth = () => {
    if (
      Array.isArray(merchants) &&
      containerRef !== null &&
      containerRef.current !== null
    ) {
      if (window.innerWidth < 767) {
        const totalPixels = containerRef.current.offsetWidth * 1.025;
        const width = totalPixels * merchants.length;
        return `${width}px`;
      }
      const totalPixels = containerRef.current.offsetWidth * 1.2;
      const width = (totalPixels / 400) * merchants.length * 190;
      return `${width}px`;
    }
  };

  const renderMerchants = () => {
    if (Array.isArray(merchants)) {
      return merchants.map((merchant) => (
        <MerchantCard
          merchant={merchant}
          setMerchant={setMerchant}
          key={merchant.merchant_id}
        />
      ));
    }
  };

  return (
    <div ref={containerRef} className="container-fluid px-0">
      <div
        className="container-fluid px-0 mw-100"
        style={{ overflowX: "scroll", overflowY: "hidden" }}
      >
        <div
          className="merchants-map-card-wrapper"
          style={{ width: getWidth() }}
        >
          {renderMerchants()}
        </div>
      </div>
    </div>
  );
};

export default MerchantList;
