import React, { useContext } from "react";
import { CapacitorContext } from "../../context/CapacitorContext";
import wallgoLogo from "../../assets/images/wallgo-logo.svg";
import arrowLeft from "../../assets/images/arrow-left.svg";
import { AuthContext } from "../../context/AuthContext";
import { hasNotch } from "../../utils";
import { Link } from "@reach/router";

const OffcanvasHeader = ({}) => {
  const { device } = useContext(CapacitorContext);
  const { user } = useContext(AuthContext);
  return (
    <div
      className="offcanvas-header"
      style={{ paddingTop: hasNotch(device) ? 100 : 0 }}
    >
      <div className="w-100">
        <div className="container-fluid py-3 px-0">
          <div className="row w-100 mx-0 align-items-center">
            <div className="col-4 px-0 text-start">
              <button className="btn px-0" data-bs-dismiss="offcanvas">
                <img src={arrowLeft} alt="arrow-left" />
              </button>
            </div>
            <div className="col-4 text-center">
              <Link to="/">
                <img
                  alt="wallgo"
                  src={wallgoLogo}
                  style={{ maxWidth: 50 }}
                  className="w-100 d-block m-auto"
                />
              </Link>
            </div>
            <div className="col-4" />
          </div>
        </div>
        <div className="main-information mt-5">
          <h2 className="h4">{user !== null ? user.name : ""}</h2>
          <h4 className="small fw-normal">{user !== null ? user.email : ""}</h4>
        </div>
      </div>
    </div>
  );
};
export default OffcanvasHeader;
