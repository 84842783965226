import React from "react";
import IconItem from "../global/IconItem";
import { navigate } from "@reach/router";

const NavbarLink = ({
  project_id,
  showNotification,
  action,
  icon,
  isRegular,
  isSidebar,
  link,
  name,
  scoped,
}) => {
  const handleClick = () => {
    if (typeof action === "function") {
      return action();
    }
    if (scoped) {
      return navigate(`/project/${project_id}${link}`);
    }
    navigate(link);
  };

  return (
    <button
      onClick={handleClick}
      data-bs-dismiss="offcanvas"
      className={`btn btn-clear border d-block px-3 py-4 w-100 no-decoration text-dark ${
        isSidebar ? "" : "w-100"
      } `}
    >
      <IconItem
        icon={icon}
        name={name}
        isSidebar={isSidebar}
        isRegular={isRegular}
        showNotification={showNotification}
      />
    </button>
  );
};

export default NavbarLink;
