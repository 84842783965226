import React from "react";
import { BASE_URL } from "../../utils";

const UserCard = ({ user, children, currentUser, handleDelete }) => {
  const renderImage = () => {
    const { file } = user;
    if (file && file !== null) {
      return (
        <img alt={user.name} src={`${BASE_URL}/${file.name}.${file.type}`} />
      );
    }

    return <i className="fa fa-user-circle fa-2x"></i>;
  };

  return (
    <div className="card p-3 shadow-sm mb-3">
      <div className="row align-items-center user-card">
        <div className="col-2">{renderImage()}</div>
        <div className="col-8">
          <h4>
            {user.name} {user.last_name}
          </h4>
          <p className="small mb-1">{user.email}</p>
          {children}
        </div>
        <div className="col-2">
          {typeof handleDelete === "function" &&
            currentUser.user_id !== user.user_id && (
              <button
                onClick={() => handleDelete(user)}
                className="btn btn-sm btn-outline-danger"
              >
                <i className="fa fa-trash"></i>
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default UserCard;
