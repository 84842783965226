import React, { useContext, useEffect } from "react";
import { WalletsContext } from "../../context/WalletsContext";
import { getValue } from "../../utils";

const WalletForm = ({ wallet_id, handleCancel }) => {
  const {
    wallet,
    saveWallet,
    createWallet,
    getSingleWallet,
    setPropertyWallet,
  } = useContext(WalletsContext);
  useEffect(() => {
    if (isNaN(parseInt(wallet_id))) {
      createWallet();
    } else {
      getSingleWallet(wallet_id);
    }
  }, [wallet_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveWallet(wallet);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Name</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(wallet, "name")}
        onChange={(e) => setPropertyWallet("name", e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-primary">
            Save Wallet
          </button>
        </div>
      </div>
    </form>
  );
};

export default WalletForm;
