import React, { createContext, useReducer } from "react";
import ProductsReducer from "../reducers/ProductsReducer";
import ProductsService from "../services/ProductsServices";
import { PRODUCTS_RECEIVED, SET_SINGLE_PRODUCT } from "../types/products";

const initialState = {
  products: [
    {
      product_id: 1,
      name: "Botella Del Día Tequila",
      amount: 132,
      SKU: "BOT-DDT",
      cost: 14,
      price: 20,
      currency: "USD",
    },
    {
      product_id: 2,
      name: "Botella Te-Ski-La",
      amount: 11,
      SKU: "BOT-TSL",
      cost: 10,
      price: 13.5,
      currency: "USD",
    },
    {
      product_id: 3,
      name: "Botella Tequilero",
      amount: 15,
      SKU: "BOT-TRO",
      cost: 12,
      price: 18,
      currency: "USD",
    },
    {
      product_id: 4,
      name: "Botella Maestro",
      amount: 0,
      SKU: "BOT-MRO",
      cost: 16,
      price: 19,
      currency: "USD",
    },
  ],
  product: null,
};

export const ProductsContext = createContext(initialState);

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductsReducer, initialState);

  const getProducts = () => {
    ProductsService.getProducts().then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECEIVED, payload: products });
    });
  };

  const getSingleProduct = (product_id) => {
    ProductsService.getSingleProduct(product_id).then((res) => {
      const { product } = res.data;
      dispatch({ type: SET_SINGLE_PRODUCT, payload: product });
    });
  };

  return (
    <ProductsContext.Provider
      value={{ ...state, getProducts, getSingleProduct }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
