import { Link } from "@reach/router";
import React, { useContext, useState } from "react";
import { WalletsContext } from "../../context/WalletsContext";
import { MenuContext } from "../../context/MenuContext";
import { useEffect } from "react";
import { S3_ENDPOINT, backgrounds } from "../../utils";
import DarkModeSwitch from "../common/DarkModeSwitch";
import FilesService from "../../services/FilesService";

const WalletCard = ({ wallet, hideButton }) => {
  const [editMode, setEditMode] = useState(false);

  const { saveWallet, setPropertyWallet } = useContext(WalletsContext);

  const getBackground = () => {
    const { background } = wallet;
    if (background && background !== null) {
      return `url("${S3_ENDPOINT}/${background.name}.${background.type}")`;
    }
  };

  const getWalletTitle = () => {
    if (wallet && wallet !== null) {
      return wallet.name;
    }
  };

  const handleUpload = (e) => {
    //e.stopPropagation();
    document.getElementById("inputFile").click();
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const formData = FilesService.getFormData(file);
    FilesService.postFile(formData).then((res) => {
      setPropertyWallet("file_id", res.data.file_id);
      setPropertyWallet("background", res.data.file);
    });
  };

  const getIndex = () => {
    let { background } = wallet;
    let index = 0;
    if (background !== null) {
      index = backgrounds.findIndex(
        (current) => current.name === background.name
      );
      if (index === -1) {
        index = 0;
      }
    }
    return index;
  };

  const handlePrev = () => {
    let index = getIndex();
    if (index === 0) {
      index = backgrounds.length - 1;
    } else {
      index -= 1;
    }
    handleSelect(index);
  };

  const handleNext = () => {
    let index = getIndex();
    if (index === backgrounds.length - 1) {
      index = 0;
    } else {
      index += 1;
    }
    handleSelect(index);
  };

  const handleSelect = (index) => {
    let selected = backgrounds[index];
    setPropertyWallet("file_id", selected.file_id);
    setPropertyWallet("background", selected);
  };

  const toggleEditMode = () => {
    if (editMode) {
      saveWallet(wallet);
    }
    setEditMode(!editMode);
  };

  const renderUsers = () => {
    const { user_wallets } = wallet;
    return (
      <div className="row">
        <div className="container-fluid px-2">
          {user_wallets.map(({ user }) => (
            <span className="badge badge-pill d-inline bg-primary small me-2">
              <i className="fa fa-user mx-1 small"></i>
              {user.name}
            </span>
          ))}
        </div>
      </div>
    );
  };

  const renderEdit = () => {
    if (wallet && wallet !== null) {
      if (editMode) {
        return (
          <input
            type="text"
            value={wallet.name}
            placeholder="Name"
            className="form-control mb-3"
            onChange={(e) => setPropertyWallet("name", e.target.value)}
          />
        );
      }
      return (
        <div>
          <h3 className={`h1 ${wallet.dark_mode ? "text-white" : ""}`}>
            {getWalletTitle()}
          </h3>
          {renderUsers()}
        </div>
      );
    }
  };

  const renderButton = () => {
    if (!hideButton) {
      return (
        <button onClick={toggleEditMode} className={`btn btn-primary`}>
          <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
        </button>
      );
    }
  };

  return (
    <Link
      to={`/wallet/${wallet.wallet_id}`}
      style={{
        backgroundImage: getBackground(),
      }}
      className={`${
        wallet.dark_mode ? "text-white" : ""
      } card wallet-card bg-primary shadow-sm no-decoration text-dark d-inline-block me-3 px-0 p-2 pb-3 mb-3`}
    >
      <div className="row align-items-center h-100 px-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-8">{renderEdit()}</div>
            <div className="col-4 text-end">{renderButton()}</div>
          </div>
          {editMode && (
            <div className="row">
              <h4
                className={`mb-2 px-4 ${wallet.dark_mode ? "text-white" : ""}`}
              >
                Fondo
              </h4>
              <div className="col-7">
                <button onClick={handlePrev} className="btn btn-light">
                  <i className="fa fa-chevron-left"></i>
                </button>
                <button onClick={handleNext} className="btn btn-light mx-2">
                  <i className="fa fa-chevron-right"></i>
                </button>
                <button onClick={handleUpload} className="btn btn-light">
                  <i className="fa fa-upload"></i>
                </button>
                <input
                  type="file"
                  id="inputFile"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={handleFile}
                  style={{ width: 0, height: 0, padding: 0 }}
                  className="form-control"
                />
              </div>
              <div className="col-5 px-0">
                <DarkModeSwitch
                  darkMode={wallet.dark_mode}
                  setDarkMode={(dark_mode) =>
                    setPropertyWallet("dark_mode", dark_mode)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default WalletCard;
