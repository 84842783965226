import React, { useState } from "react";
import VehicleCard from "./VehicleCard";

const SelectVehicle = ({ title, vehicles, handleCancel, handleSubmit }) => {
  const [selected, setSelected] = useState(null);

  const handleSelect = (vehicle) => {
    setSelected(vehicle.vehicle_id);
  };

  return (
    <div>
      {vehicles.map((vehicle) => (
        <VehicleCard
          vehicle={vehicle}
          selected={selected}
          onClick={handleSelect}
          key={vehicle.vehicle_id}
        />
      ))}
      <button
        onClick={() => handleSubmit(selected)}
        className="btn btn-primary w-100 my-3"
      >
        Acceder
      </button>
      <button onClick={handleCancel} className="btn text-muted w-100">
        Cancelar
      </button>
    </div>
  );
};

export default SelectVehicle;
