import { Link } from "@reach/router";
import React, { useContext } from "react";
import IconItem from "../global/IconItem";
import { AuthContext } from "../../context/AuthContext";

const OffcanvasFooter = () => {
  const { signOut } = useContext(AuthContext);
  return (
    <div className="offcanvas-footer">
      <div className="row w-100">
        <div className="col-6">
          <Link to="/help" className="btn py-0 px-0 w-100">
            <IconItem icon="exclamation-circle" name="Help" />
          </Link>
        </div>
        <div className="col-6">
          <button onClick={signOut} className="btn py-0 px-0 w-100">
            <IconItem icon="sign-out-alt" name="Sign Out" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OffcanvasFooter;
