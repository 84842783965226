import React, { useContext, useEffect } from "react";
import WalletContainer from "../components/wallet/WalletContainer";
import { WalletsContext } from "../context/WalletsContext";
import { MenuContext } from "../context/MenuContext";
import ActiveTickets from "./ActiveTickets";
import MerchantList from "./MerchantList";
import { Link } from "@reach/router";
import Greeting from "./Greeting";

const Dashboard = () => {
  const { wallets, getWallets } = useContext(WalletsContext);
  const { setMode, setSelected } = useContext(MenuContext);

  useEffect(() => {
    getWallets();
    setMode("dark");
    setSelected("Welcome");
    return () => {
      setMode("light");
    };
  }, []);

  return (
    <div className="container-fluid">
      <Greeting />
      <WalletContainer wallets={wallets} />
      <ActiveTickets />
      <div className="row mb-1 align-items-center">
        <div className="col-8">
          <h2 className="mb-0">Available Parking</h2>
        </div>
        <div className="col-4 text-end">
          <Link to="/merchants" className="btn btn-sm text-muted">
            See All
          </Link>
        </div>
      </div>
      <MerchantList />
    </div>
  );
};

export default Dashboard;
