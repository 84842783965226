import React, { useContext, useEffect } from "react";
import GoogleMarkersMap from "../components/maps/GoogleMarkersMap";
import { CapacitorContext } from "../context/CapacitorContext";
import { MerchantContext } from "../context/MerchantContext";
import { MenuContext } from "../context/MenuContext";
import Navbar from "../components/navbar/Navbar";
import { S3_ENDPOINT, hasNotch } from "../utils";
import MerchantList from "./MerchantList";

const Merchants = () => {
  const { device } = useContext(CapacitorContext);
  const { setSelected } = useContext(MenuContext);
  const { merchant, merchants, setMerchant, getMerchants } =
    useContext(MerchantContext);

  useEffect(() => {
    getMerchants();
    setSelected("Comercios");
  }, []);

  const renderMerchantsMap = () => {
    if (Array.isArray(merchants)) {
      return (
        <GoogleMarkersMap
          center={merchant}
          markers={merchants}
          base_url={S3_ENDPOINT}
          selectedMarker={merchant}
          zoom={merchant !== null ? 15 : 12}
        />
      );
    }
  };

  const renderSearch = () => {
    return (
      <div className="merchant-search-wrapper px-2" style={{ top: 140 }}>
        <input
          type="text"
          className="form-control"
          placeholder="Buscar comercios..."
        />
      </div>
    );
  };

  const renderMerchantsCards = () => {
    if (Array.isArray(merchants))
      return (
        <div
          className="container-fluid"
          style={{
            bottom: 0,
            zIndex: 10,
            position: "absolute",
          }}
        >
          <MerchantList setMerchant={setMerchant} />
        </div>
      );
  };

  return (
    <div id="merchants" className="container-fluid px-0">
      <Navbar clear />
      <div className="container">{renderSearch()}</div>
      {renderMerchantsMap()}
      <div className="container">{renderMerchantsCards()}</div>
    </div>
  );
};

export default Merchants;
