import api from "./api";
import { getArgs } from "../utils";

const route = "/tickets";

const TicketService = {
  getMyTickets: (filters) => api.get(`${route}?${getArgs(filters)}`),
  attemptTicket: (merchant_id, license_plate) =>
    api.post(`${route}/${merchant_id}/vehicle/${license_plate}`),
};
export default TicketService;
