import React from "react";

const Greeting = () => {
  return (
    <div id="greeting" className="row align-items-end mb-3 position-relative">
      <div className="container-fluid py-4 text-white" style={{ zIndex: 1 }}>
        <h2>Find the best parking space</h2>
        <p className="mb-0 text-info">Your Location</p>
        <button className="btn btn-sm text-white px-0 mb-3">
          Monterrey, Nuevo León <i className="fa fa-chevron-down ms-1" />
        </button>
        <div className="input-group bg-white mb-3">
          <span className="input-group-text" id="search-addon">
            <i className="fa fa-search text-muted"></i>
          </span>
          <input
            type="text"
            placeholder="Search..."
            className="form-control"
            alia-aria-describedby="search-addon"
          />
        </div>
      </div>
      <div className="overlay bg-primary"></div>
    </div>
  );
};

export default Greeting;
