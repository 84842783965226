import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { hasNotch } from "../../utils";
import { CapacitorContext } from "../../context/CapacitorContext";

const SuccessAlert = () => {
  const { device } = useContext(CapacitorContext);
  const { showSuccess, successContent, clearSuccess } =
    useContext(ModalContext);

  return (
    <div
      className={`alert alert-success alert-dismissible fixed-top ms-auto me-2 fade-show mt-2 ${
        !showSuccess ? "hidden" : ""
      }`}
      role="alert"
      style={{
        maxWidth: 500,
        zIndex: 2500,
        marginTop: hasNotch(device) ? 64 : "inherit",
      }}
    >
      {successContent}
      <button className="btn-close" onClick={clearSuccess}></button>
    </div>
  );
};

export default SuccessAlert;
