import React from "react";
import Page from "../components/global/Page";

const Terms = () => {
  return (
    <Page title="Términos y Condiciones">
      <p>
        Los términos y condiciones descritos en el presente serán aplicables
        para el uso de la plataforma WallGo (wallgoapp.com) la cual está
        disponible para teléfonos celulares, tablets, computadora, etc., en
        particular para utilizar y recibir los Servicios (según dicho término se
        define más adelante).
      </p>
      <h4>TÉRMINOS Y CONDICIONES DE USO</h4>
      <p>
        Los términos y condiciones descritos en el presente serán aplicables
        para el uso de la plataforma WallGo (wallgoapp.com) la cual está
        disponible para teléfonos celulares, tablets, computadora, etc., en
        particular para utilizar y recibir los Servicios (según dicho término se
        define más adelante).
      </p>
      <h4>
        LE ROGAMOS LEA LAS PRESENTES CONDICIONES DEL USUARIO ATENTAMENTE ANTES
        DE USAR NUESTROS SERVICIOS.
      </h4>
      <p>
        Estos términos y condiciones de uso constituyen un Contrato de Adhesión
        entre usted y WALLGO SA DE CV, la cual es una persona física con
        actividad empresarial, constituida conforme a las leyes aplicables de
        México, con RFC XXX XXXXX XXX. El domicilio de WallGo para todo lo
        relativo a este contrato es el ubicado en DOMICILIO
      </p>
      <h4>¿QUÉ SERVICIOS PRESTA wallgoapp.com?</h4>
      <p>
        Presta a los Usuarios servicios que consiente en la realización por
        éstos de ejercicios de alto rendimiento entre los cuales se encuentran,
        a manera de ejemplificar pero sin limitar: clases de barre, jump, HIIT,
        yoga, pilates; así como ejercicios físicos mediante el uso de mancuernas
        y ligas en las clases y por nuestros entrenadores, horarios y locales de
        WallGo abiertos o que se abran en México, y en servicios relacionados a
        dichas clases, incluyendo asesoría en materia de acondicionamiento
        físico y nutrición. (“Servicio”).
      </p>
      <h4>¿CÓMO SE SUSCRIBE UN CONTRATO ENTRE THE BODY METHOD Y USTED?</h4>
      <p>
        Para poder utilizar los Servicios, usted deberá de ingresar al sitio web
        wallgoapp.com y solicitar en el mismo el alta de su cuenta de usuario
        personal, siguiendo las instrucciones y proporcionando la información en
        el sitio web requerida. Para poder hacer uso del sitio web o cualquier
        otro producto o servicio vinculado a estos Términos y Condiciones deberá
        haber aceptado dichos Términos.
      </p>
      <p>
        Le suplicamos que, si no acepta los Términos y Condiciones del Servicio
        y/o Aviso de Privacidad, no utilice los Servicios; al momento de crear
        una cuenta con nosotros y hacer uso del sitio web y de cualquier otro
        servicio de WallGo usted expresamente consiente los Términos y
        Condiciones del Servicio, así como muestra su conformidad con el Aviso
        de Privacidad que hemos puesto a su consideración.
      </p>
      <p>
        Dicha aceptación se entenderá como el consentimiento expreso entre usted
        y WallGo para celebrar el presente contrato y cumplir con las
        obligaciones en el mismo establecidas (“Contrato”). Al momento en que
        usted acepte los presentes Términos y Condiciones del Servicio, WallGo
        autorizará el alta de su cuenta de usuario personal. Usted se obliga y
        acepta entregar a WallGo la información personal que WallGo considere en
        esta fecha o en fecha posterior necesaria o conveniente para poder
        brindar los Servicios. Sus datos personales serán tratados conforme al
        Aviso de privacidad de WallGo, más adelante transcrito, y en
        cumplimiento a lo dispuesto por la Ley Federal de Protección de Datos
        Personales en Posesión de los Particulares. Usted acepta proporcionar
        los datos de su tarjeta de crédito u otro medio de pago electrónico que
        WallGo acepte como medio de pago por los Servicios, y manifiesta su
        aprobación para que WallGo realice los cargos que correspondan por los
        Servicios contratados. Tras cumplimentar adecuadamente el registro con
        WallGo; WallGo by Vogel le proporcionará una cuenta personal a la cual
        usted podrá acceder a través del sitio web al ingresar con su nombre de
        usuario y la contraseña que usted elija. Dicha cuenta podrá ser
        utilizada en el sitio web de WallGo wallgoapp.com y viceversa. En cuanto
        al sitio web, le sugerimos que lea cuidadosamente los
      </p>
      <p>
        Términos y Condiciones, así como el aviso de Privacidad que ponemos a su
        alcance. Usted es el único responsable del manejo de su contraseña y de
        mantenerla confidencial. Usted acepta y reconoce que las autorizaciones,
        contrataciones de Servicios y de consentimientos que se realicen
        utilizando su nombre de usuario y contraseña se entenderán hechas por
        usted en lo personal y como consentimiento expreso de su parte. Por lo
        anterior le recomendamos no revelar a persona alguna su nombre de
        usuario y/o contraseña. WallGo no será responsable por el uso
        inadecuado, o los cargos que se realicen en su tarjeta de crédito u otro
        medio de pago que haya proporcionado a través del uso de su nombre de
        usuario y contraseña.
      </p>
      <h4>USO DEL SITIO WEB O EL SERVICIO</h4>
      <p>
        Usted deberá garantizar que la información proporcionada a WallGo es
        completa y veraz. WallGo tendrá derecho, en todo momento, a comprobar la
        veracidad de la información facilitada.
      </p>
      <p>
        Es su responsabilidad asegurarse de ingresar al sitio web correcto
        wallgoapp.com
      </p>
      <p>
        WallGo se reserva el derecho a limitar, restringir o incluso prohibir su
        ingreso a wallgoapp.com con su nombre de usuario y la contraseña. Así
        mismo, WallGo se reserva el derecho de limitar, restringir o prohibir en
        cualquier momento que usted utilice sus Servicios, por cualquier causa y
        sin necesidad de justificarlo.
      </p>
      <p>
        Al utilizar la plataforma WallGo o los Servicios usted consiente que:
      </p>
      <ol>
        <li>
          Sólo utilizará los servicios y wallgoapp.com exclusivamente de manera
          personal, por lo que no podrá ceder sus espacios en clases u otros
          servicios en favor de terceros;
        </li>
        <li>No autorizará a otros usar su cuenta;</li>
        <li>
          No cederá ni transferirá de otro modo su cuenta o derechos bajo este
          contrato a ninguna otra persona o entidad legal;
        </li>
        <li>
          No utilizará una cuenta que esté sujeta a cualquier derecho de una
          persona que no sea usted sin la autorización adecuada;
        </li>
        <li>
          No utilizará los servicios o wallgoapp.com e con fines ilícitos,
          incluyendo, sin limitación para enviar o almacenar ningún material
          ilegal o con fines fraudulentos.
        </li>
        <li>
          No utilizará los servicios o wallgoapp.com para causar molestias,
          trastornos o inconvenientes;
        </li>
        <li>No perjudicará el funcionamiento adecuado de la red;</li>
        <li>
          No tratará de dañar los servicios o wallgoapp.com de ningún modo;
        </li>
        <li>
          No copiará ni distribuirá ningún contenido o servicios de
          wallgoapp.com sin el permiso escrito de WallGo.
        </li>
        <li>
          Guardará de forma segura y confidencial la contraseña de su cuenta y
          cualquier identificación facilitada para permitirle acceder al
          servicio y wallgoapp.com ;
        </li>
        <li>
          Nos facilitará todas las pruebas de identidad que le solicitemos
          razonablemente de tiempo en tiempo;
        </li>
        <li>
          Cumplirá con toda la legislación y normas aplicables al usar la
          wallgoapp.com o los servicios;
        </li>
        <li>
          Mantendrá una buena conducta y será respetuoso con las demás personas
          que utilicen los servicios;
        </li>
        <li>
          Se obliga a respetar y cumplir los reglamentos que WallGo by Vogel
          emita con relación a los servicios.
        </li>
      </ol>
      <p>
        WallGo se reserva el derecho a terminar en cualquier momento y de manera
        inmediata sin necesidad de declaración judicial los Servicios objeto de
        las presentes Condiciones de Usuario, en caso de que el Usuario incumpla
        con cualquiera de las normas anteriores.
      </p>
      <h4>PAGO</h4>
      <p>
        El uso de la plataforma wallgoapp.com , una vez suscrito a nuestro
        Servicio podrá encontrar información sobre las tarifas aplicables para
        los Servicios.
      </p>
      <p>
        WallGo podrá modificar o actualizar las mismas ocasionalmente, sin
        necesidad de previo aviso. Será su responsabilidad mantenerse informado
        sobre las tarifas actuales para disfrutar y contratar los Servicios, por
        su parte WallGo le cobrará por los Servicios que usted elija. Usted
        acuerda pagar todos los Servicios que solicite (con independencia de si
        los utiliza o no), mediante cargo automático a la tarjeta de crédito o
        medio de pago electrónico que usted haya proporcionado a través de
        wallgoapp.com . El costo de los Servicios causa Impuesto al Valor
        Agregado. En todo caso, usted será responsable del pago puntual de todos
        los Servicios que solicite. Los pagos y cargos realizados no son
        reembolsables. WallGo by Vogel podrá utilizar procesadores de pagos de
        terceros (“Procesador de Pago”) para vincular su tarjeta de crédito o el
        medio de pago electrónico que nos proporcione a su nombre de usuario y
        contraseña de wallgoapp.com . El procesamiento de pagos, con respecto al
        uso que haga de los servicios estará sujeto a las condiciones y
        políticas de privacidad del Procesador de Pago y el emisor de su tarjeta
        de crédito además de a estos Términos y Condiciones de Servicio. WallGo
        by Vogel no será responsable de ningún error del Procesador de Pago. En
        relación con el uso de wallgoapp.com y los servicios, WallGo by Vogel
        obtendrá determinados datos de la transacción, que WallGo by Vogel
        utilizará únicamente de conformidad al Aviso de Privacidad de WallGo.
      </p>
      <h4>INDEMNIZACIÓN</h4>
      <p>
        Al aceptar estos términos y condiciones del usuario y utilizar el nombre
        de usuario y contraseña en WallGo, usted acuerda y acepta que libera a
        WallGo de toda y cualquier responsabilidad, y se obliga a indemnizar y
        mantener indemne a WallGo by Vogel, sus líales, licenciatarios y todos
        sus directivos, directores, instructores, otros usuarios, trabajadores,
        representantes y asesores por cualesquiera reclamaciones, costos, daños,
        pérdidas, responsabilidades y gastos (incluyendo horarios y gastos de
        abogados) derivados de o en relación con: Violación o incumplimiento de
        cualquier condición de estos Términos y Condiciones del Servicio o
        cualquier ley o reglamento aplicable, se haga o no referencia al mismo
        en el presente; Violación de cualquier derecho de cualquier tercero; Uso
        incorrecto de wallgoapp.com y los Servicios.
      </p>
      <h4>RESPONSABILIDAD</h4>
      <p>
        La información, recomendaciones u otros servicios prestados en o a
        través de wallgoapp.com y/o por el uso de los Servicios, son sólo
        información general y no constituyen un aviso. WallGo se ocupará de
        mantener wallgoapp.com y sus contenidos de forma razonablemente correcta
        y actualizada, pero no podrá garantizar que los contenidos de
        wallgoapp.com carezcan de errores, defectos, malware y virus y que
        wallgoapp.com sean correctos, estén actualizados y sean precisos. WallGo
        no será responsable por ningún daño derivado del uso del (o incapacidad
        de usar) wallgoapp.com incluyendo los daños causados por malware, virus
        o cualquier información incorrecta o incompleta de la información
        wallgoapp.com , salvo que este daño se derive de cualquier conducta
        dolosa o negligencia grave por parte de WallGo. Asimismo, WallGo no será
        responsable por los daños derivados del uso de (o incapacidad de usar)
        los medios de comunicación electrónicos con wallgoapp.com , incluyendo
        sin limitación daños derivados de fallo o retraso en la entrega de
        comunicaciones electrónicas, intercepción o manipulación de
        comunicaciones electrónicas por terceros o por programas informáticos
        usados para comunicaciones electrónicas y transmisión de virus.
      </p>
      <h4>VIGENCIA Y FINALIZACION DEL CONTRATO</h4>
      <p>
        Se suscribe el presente Contrato entre WallGo y usted por un periodo
        indefinido y durante el tiempo que usted utilice y acceda a
        wallgoapp.com . Usted y WallGo tendrán derecho a analizar el contrato en
        todo momento, siempre y cuando usted mediante escrito libre solicite a
        WallGo deshabilite su nombre de usuario de wallgoapp.com . WallGo tendrá
        derecho a terminar el contrato en todo momento y con efecto inmediato
        (deshabilitando el uso del Servicio) si usted: Viola o incumple
        cualquier condición de las presentes Condiciones del Usuario; A
        consideración de WallGo, hace un uso indebido de wallgoapp.com o los
        servicios. WallGo no estará obligado a dar un aviso previo de la
        terminación de Contrato. Después de su terminación WallGo avisará de
        ello con arreglo a las presentes Condiciones de Usuario.
      </p>
      <h4>MODIFICACIÓN DE LOS SERVICIOS Y LAS CONDICIONES DEL USUARIO</h4>
      <p>
        WallGo se reserva el derecho, a su discreción, de modificar o sustituir
        cualquiera de los presentes Términos y Condiciones del Servicio, o
        cambiar, suspender o interrumpir los Servicios o el acceso a
        wallgoapp.com (incluyendo, sin limitación, la disponibilidad de
        cualquier característica, base de datos o contenido) en cualquier
        momento mediante la publicación de un aviso en wallgoapp.com o a través
        de correo electrónico.
      </p>
      <h4>NOTIFICACIONES</h4>
      <p>
        WallGo podrá emitir notificaciones o avisos a usted a través de un aviso
        general en wallgoapp.com o por correo electrónico a la dirección
        registrada en la información de la cuenta de wallgoapp.com o mediante
        una comunicación escrita enviada por correo ordinario a la dirección
        registrada en la información de la cuenta de WallGo by Vogel.
      </p>
      <h4>JURISDICCIÓN Y LEY APLICABLE</h4>
      <p>
        Las partes están de acuerdo en que el presente Contrato se regirá por
        las leyes aplicables en México. Para la interpretación y cumplimiento
        del Contrato, las partes se someten a la jurisdicción de los tribunales
        competentes en México, renunciando expresamente a cualquier otro fuero
        que pudiera corresponderles por razón de sus domicilios presentes o
        futuros o por cualquier otra causa.
      </p>
      <h4>ACEPTACIÓN DE RIESGO, RENUNCIA Y LIBERACION DE RESPONSABILIDAD</h4>
      <p>
        Mediante la inscripción a WallGo y/o asistiendo a clases, eventos,
        actividades y otros programas y el uso de las instalaciones y el equipo
        (“Clases” y/o “instalaciones”) de WallGo by Vogel, por la presente
        reconozco que existen ciertos riesgos y peligros inherentes al uso y
        práctica de cualquier ejercicio físico y en específico, pero sin
        limitar; a la práctica y uso de equipo, durante las clases que se
        imparten en WallGo. También reconozco que los riesgos específicos varían
        de una actividad a otra, mismos que podrían ser lesiones menores como:
        a) 1. Rasguños, 2. Golpes y torceduras; b) lesiones mayores como 1.
        Lesiones en articulaciones o espalda, 2. Ataques cardiacos; y 3.
        Contusiones; y c) lesiones graves, incluyendo parálisis, y muerte por lo
        que expresamente reconozco y acepto que dichos riesgos no pueden ser
        eliminados por WallGo by Vogel y que dependen de usted al realizarlos,
        por lo cual recomendamos ampliamente que cuando realice actividad física
        sea conforma a su capacidad para llevarla a cabo, y estando en perfectas
        condiciones físicas para practicar las actividades que WallGo pone a su
        alcance.
      </p>
      <p>
        Me comprometo a cumplir con todos los términos y condiciones
        establecidos en dichos documentos, así como las instrucciones que de
        tiempo en tiempo el personal de WallGo me proporcionó durante el
        desarrollo de las clases, o en su caso, con las instrucciones que WallGo
        ponga en el establecimiento donde se lleven a cabo las clases. Si en
        cualquier momento, el personal de WallGo by Vogel me sugiere y me indica
        que no podré llevar a cabo cualesquiera de las clases que se imparten
        acataré dicha instrucción. Lo anterior, basado en la opinión del
        personal de WallGo quienes reconozco están debidamente capacitados para
        emitir dicha opinión, por lo que entiendo y acepto que dicha opinión
        siempre será en mi beneficio y en cuidado de mi salud. En relación con
        lo anterior, en caso de que WallGo me permita tomar las clases, asumo
        plena responsabilidad por cualquier y todas las lesiones o daños que
        sufra (incluso muerte) durante o derivado de las clases; libero a WallGo
        by Vogel y sus subsidiarias, y casa uno de sus socios, accionistas,
        consejeros, funcionarios, directores, empleados, representantes y
        agentes, y casa uno de sus respectivos sucesores y cesionarios de
        cualquier y toda responsabilidad, reclamaciones, acciones, demandas,
        procedimientos, costos, gastos, daños y pasivos; y manifiesto que al día
        de la presente no tengo ningún impedimento médico que me impida tomar
        las clases o usar correctamente los apartados mediante los cuales se
        llevan a cabo las clases; no tengo una condición física o mental que me
        ponga en peligro médico y físico; y no tengo instrucciones médicas que
        me limiten o restrinjan realizar cualquier tipo de actividad física.
        Reconozco que, si tengo alguna discapacidad o enfermedad crónica, estoy
        en riesgo al hacer uso de las instalaciones y acudir a las clases, y que
        no debería de participar en cualquiera de las clases. He leído esta
        declaratoria de aceptación de riesgo, renuncia y liberación de
        responsabilidad, y deslindo de toda responsabilidad, obligándome a sacar
        en paz y a salvo a WallGo y/o todas sus subsidiarias, y a cada uno de
        sus socios, accionistas, consejeros, funcionarios, directores,
        empleados, representantes y agentes respecto de toda acción, demanda,
        responsabilidad de carácter civil o penal derivado de cualquier
        contingencia, accidente, daño o cualquier tipo de lesión, enfermedad,
        fracturas, incapacidad parcial o permanente y/o la muerte que pudiera
        sufrir el que suscribe por el uso de las instalaciones WallGo y/o por
        las clases que tome. Reconozco que estoy firmando el presente de manera
        libre y voluntariamente y que la vigencia de esta renuncia es indefinida
        por lo que continuará válida y vigente durante el tiempo que acuda a las
        instalaciones y/o tome las clases de WallGo.
      </p>
      <h4>Última revisión: Enero 2023.</h4>
    </Page>
  );
};

export default Terms;
