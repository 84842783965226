import {
  CREATE_VEHICLE,
  SET_PROPERTY_VEHICLE,
  SET_VEHICLE,
  VEHICLES_RECEIVED,
} from "../types/vehicles";

const schema = {
  vehicle_id: "nuevo",
  license_plate: "",
  brand: "",
  model: "",
};

const VehiclesReducer = (state, { type, payload }) => {
  switch (type) {
    case VEHICLES_RECEIVED:
      return { ...state, vehicles: payload };
    case SET_VEHICLE:
      return { ...state, vehicle: payload };
    case CREATE_VEHICLE:
      return { ...state, vehicle: schema };
    case SET_PROPERTY_VEHICLE:
      const { key, value } = payload;
      const vehicle = { ...state.vehicle };
      vehicle[key] = value;
      return { ...state, vehicle };
    default:
      return { ...state };
  }
};
export default VehiclesReducer;
