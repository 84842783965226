import api from "./api";
import { getArgs } from "../utils";

const route = "/charges";

const ChargesService = {
  getCharges: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSingleCharge: (charge_id) => api.get(`${route}/${charge_id}`),
};
export default ChargesService;
