import {
  MERCHANTS_RECEIVED,
  SET_MERCHANT,
  SINGLE_MERCHANT_RECEIVED,
} from "../types/merchants";

const MerchantReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_MERCHANT:
      return { ...state, merchant: payload };
    case MERCHANTS_RECEIVED:
      return { ...state, merchants: payload };
    case SINGLE_MERCHANT_RECEIVED:
      return { ...state, merchant: payload };
    default:
      return { ...state };
  }
};
export default MerchantReducer;
