import { CLEAR_FILTERS, SET_FILTER } from "../types/filters";

const FiltersReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_FILTER:
      let { key, value } = payload;
      if (value === "" || value === "null" || value === null) {
        value = undefined;
      }
      return { ...state, filters: { ...state.filters, [key]: value } };
    case CLEAR_FILTERS:
      return { ...payload };
    default:
      return { ...state };
  }
};
export default FiltersReducer;
