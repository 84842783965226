import { Router } from "@reach/router";
import React from "react";
import Login from "./Login";
import SignUp from "./SignUp";
import Recovery from "./Recovery";
import SignUpPhone from "./SignUpPhone";
import LoginPhone from "./LoginPhone";

const Auth = () => {
  return (
    <Router>
      <Login path="/login" default />
      <SignUp path="/signup" />
      <Recovery path="/recovery" />
      <LoginPhone path="/login/phone" />
      <SignUpPhone path="/signup/phone" />
    </Router>
  );
};

export default Auth;
