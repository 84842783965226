import React, { useContext, useEffect } from "react";
import PaymentSourceCard from "../components/payment_sources/PaymentSourceCard";
import MerchantDetails from "../components/merchants/MerchantDetails";
import SingleChargeCard from "../components/charges/SingleChargeCard";
import VehicleCard from "../components/vehicles/VehicleCard";
import { ChargesContext } from "../context/ChargesContext";
import { MenuContext } from "../context/MenuContext";

const SingleCharge = ({ charge_id }) => {
  const { setSelected } = useContext(MenuContext);
  const { charge, getSingleCharge } = useContext(ChargesContext);

  useEffect(() => {
    setSelected("Charge Detail");
    getSingleCharge(charge_id);
  }, []);

  const renderPaymentSource = (payment_source) => {
    if (payment_source && payment_source !== null) {
      return (
        <PaymentSourceCard
          hideCheck
          hideButtons
          payment_source={payment_source}
        />
      );
    }
  };

  const renderVehicle = (vehicle) => {
    if (vehicle && vehicle !== null) {
      return <VehicleCard vehicle={vehicle} />;
    }
  };

  const renderCharge = () => {
    if (charge && charge !== null) {
      const { merchant, vehicle, payment_source } = charge;
      return (
        <div>
          <h3>Comercio</h3>
          <MerchantDetails merchant={merchant} />
          <h3>Cargo</h3>
          <SingleChargeCard charge={charge} hideButton />
          <h3>Método de Pago</h3>
          {renderPaymentSource(payment_source)}
          <h3>Vehículo</h3>
          {renderVehicle(vehicle)}
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return <div className="container mt-3">{renderCharge()}</div>;
};

export default SingleCharge;
