import React, { useContext, useEffect } from "react";
import { VehiclesContext } from "../../context/VehiclesContext";
import { getLicensePlate, getValue } from "../../utils";

const VehicleForm = ({ handleCancel }) => {
  const { vehicle, postVehicle, setPropertyVehicle } =
    useContext(VehiclesContext);

  useEffect(() => {
    if (!vehicle.license_plate) {
      let license_plate = getLicensePlate(vehicle);
      setPropertyVehicle("license_plate", license_plate);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    postVehicle(vehicle);
  };

  return (
    <form className="card shadow my-3" onSubmit={handleSubmit}>
      <label>Nombre</label>
      <input
        type="text"
        placeholder="Rockman"
        className="form-control mb-3"
        value={getValue(vehicle, "name")}
        onChange={(e) => setPropertyVehicle("name", e.target.value)}
      />
      <label>Placa</label>
      <input
        type="text"
        className="form-control mb-3"
        value={vehicle.license_plate ? vehicle.license_plate : ""}
        onChange={(e) => setPropertyVehicle("license_plate", e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <label>Marca</label>
          <input
            type="text"
            className="form-control mb-3"
            value={vehicle.make}
            onChange={(e) => setPropertyVehicle("make", e.target.value)}
          />
        </div>
        <div className="col-6">
          <label>Modelo</label>
          <input
            type="text"
            className="form-control mb-3"
            value={vehicle.model}
            onChange={(e) => setPropertyVehicle("model", e.target.value)}
          />
        </div>
      </div>
      <label>Año</label>
      <input
        type="number"
        className="form-control mb-3"
        value={vehicle.year}
        onChange={(e) => setPropertyVehicle("year", e.target.value)}
      />
      <button
        type="submit"
        onClick={handleSubmit}
        className="btn btn-primary w-100 mb-4"
      >
        Guardar
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="btn btn-link text-secondary w-100"
      >
        Cancelar
      </button>
    </form>
  );
};

export default VehicleForm;
