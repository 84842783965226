import {
  TICKETS_RECEIVED,
  TICKET_DENIED,
  TICKET_GRANTED,
} from "../types/tickets";

const TicketReducer = (state, { type, payload }) => {
  switch (type) {
    case TICKETS_RECEIVED:
      return { ...state, tickets: payload };
    case TICKET_GRANTED:
      return { ...state, ticket: payload };
    case TICKET_DENIED:
      return { ...state, ticket: false };
    default:
      return { ...state };
  }
};
export default TicketReducer;
