import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import FacebookLogin from "react-facebook-login";
import { addFormEventListeners, S3_ENDPOINT } from "../utils";
import { navigate } from "@reach/router";
import { Link } from "@reach/router";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { correo, user, signIn, signInWithGoogle, facebookSignIn } =
    useContext(AuthContext);

  useEffect(() => {
    addFormEventListeners();
  }, []);

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (correo !== null && email === "") {
      setEmail(correo);
    }
  }, [correo, email]);

  const toggleSignInWithGoogle = (e) => {
    e.preventDefault();
    signInWithGoogle();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
  };

  return (
    <div className="container py-3">
      <img
        src={`${S3_ENDPOINT}/logo.png`}
        className="m-auto d-block"
        style={{ width: 200 }}
        alt="logo"
      />
      <form className="card mb-5" onSubmit={handleSubmit}>
        <h3>Welcome Back!</h3>
        <div className="input-group mb-3">
          <span className="input-group-text" id="email-addon">
            <i className="fa fa-envelope text-primary"></i>
          </span>
          <input
            type="text"
            value={email}
            placeholder="Email"
            className="form-control"
            alia-aria-describedby="email-addon"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="password-addon">
            <i className="fa fa-lock text-primary"></i>
          </span>
          <input
            className="form-control"
            placeholder="Password"
            value={password}
            alia-aria-describedby="password-addon"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="input-group-text">
            <button
              type="button"
              className="btn px-0 py-0"
              onClick={() => setShowPassword(!showPassword)}
            >
              <i className={`fas fa-eye${showPassword ? "" : "-slash"}`}></i>
            </button>
          </span>
        </div>
        <div className="container-fluid text-end">
          <Link to="/recovery" className="text-primary no-decoration">
            Forgot password?
          </Link>
        </div>
        <div className="container-fluid px-0 mb-3">
          <button className="btn btn-primary w-100 d-block m-auto my-4">
            Login
          </button>
        </div>
        <div className="container-fluid text-center mb-4">or login using</div>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-danger w-100"
              onClick={toggleSignInWithGoogle}
            >
              <i className="fab fa-google me-2"></i>
              Google
            </button>
          </div>
          <div className="col-6">
            <FacebookLogin
              appId="535175042039110"
              fields="name,email,picture"
              callback={facebookSignIn}
              textButton="Facebook"
              icon={<i className="fab fa-facebook me-2"></i>}
              cssClass="btn w-100 btn-outline-primary"
            />
          </div>
        </div>
      </form>
      <div className="container-fluid text-center">
        Don't have an account?
        <Link
          to="/auth/signup"
          className="text-accent no-decoration bold mx-2 text-center"
        >
          Sign up here
        </Link>
      </div>
    </div>
  );
};

export default Login;
