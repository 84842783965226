import React, { createContext, useReducer } from "react";
import ChargesReducer from "../reducers/ChargesReducer";
import ChargesService from "../services/ChargesService";
import { SET_CHARGE, CHARGES_RECEIVED } from "../types/charges";
import { MERCHANTS_RECEIVED } from "../types/merchants";

const initialState = {
  charges: null,
  charge: null,
};

export const ChargesContext = createContext(initialState);

export const ChargesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ChargesReducer, initialState);

  const getCharges = (filters) => {
    dispatch({ type: CHARGES_RECEIVED, payload: null });
    ChargesService.getCharges(filters).then((res) => {
      const { charges, merchants } = res.data;
      dispatch({ type: MERCHANTS_RECEIVED, payload: merchants });
      dispatch({ type: CHARGES_RECEIVED, payload: charges });
    });
  };

  const getSingleCharge = (charge_id) => {
    ChargesService.getSingleCharge(charge_id).then((res) => {
      const { charge } = res.data;
      dispatch({ type: SET_CHARGE, payload: charge });
    });
  };

  return (
    <ChargesContext.Provider value={{ ...state, getCharges, getSingleCharge }}>
      {children}
    </ChargesContext.Provider>
  );
};
