import React, { useContext, useEffect } from "react";
import { CapacitorContext } from "./context/CapacitorContext";
import SuccessAlert from "./components/common/SuccessAlert";
import MobileModal from "./components/global/MobileModal";
import ErrorAlert from "./components/common/ErrorAlert";
import { AuthContext } from "./context/AuthContext";
import Modal from "./components/global/Modal";
import Merchants from "./views/Merchants";
import ThankYou from "./views/ThankYou";
import { Router } from "@reach/router";
import Privacy from "./views/Privacy";
import Terms from "./views/Terms";
import Panel from "./views/Panel";
import Auth from "./views/Auth";
import "./css/landing.css";
import "./css/colors.css";
import "./css/fonts.css";
import { hasNotch } from "./utils";
import PreguntasFrecuentes from "./views/pages/PreguntasFrecuentes";
import Loading from "./views/Loading";
import Welcome from "./views/Welcome";

const Main = () => {
  const { user, userLoggedIn } = useContext(AuthContext);
  const { device, platform, setupDevice, setupPlatform, setupStatusBar } =
    useContext(CapacitorContext);

  useEffect(() => {
    userLoggedIn();
    setupDevice();
    setupPlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (platform !== null && platform !== "web") {
      setupStatusBar();
    }
  }, [platform]);

  return (
    <div
      className="container-fluid px-0"
      style={{ paddingTop: hasNotch(device) ? 32 : 0 }}
    >
      <Router>
        {user !== null ? (
          <Panel path="/*" default />
        ) : (
          [
            <Auth path="/auth/*" key="auth" />,
            <Welcome path="/" key="welcome" />,
            <Loading path="/*" key="loading" />,
          ]
        )}
        <Merchants path="/merchants" />
        <ThankYou path="/thankyou/:purchase_id/*" />
        <Terms path="/terminos-y-condiciones" />
        <PreguntasFrecuentes path="/faq" />
        <Privacy path="/privacidad" />
      </Router>
      <Modal />
      <MobileModal />
      <ErrorAlert />
      <SuccessAlert />
    </div>
  );
};

export default Main;
