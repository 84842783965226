import React, { useContext, useEffect } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import { AuthContext } from "../context/AuthContext";
import { formatMonto, S3_ENDPOINT } from "../utils";
import { Link } from "@reach/router";
import moment from "moment";

const ThankYou = ({ purchase_id }) => {
  const { purchase, getPurchase } = useContext(PurchasesContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null && purchase === null) {
      getPurchase(purchase_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (purchase !== null) {
      if (purchase.name === null) {
        setTimeout(() => {
          getPurchase(purchase_id);
        }, 3000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase]);

  const renderTotal = () => {
    if (purchase.free_trial_length) {
      return (
        <div>
          <p>Total Pagado: $0 MXN</p>
          <p>
            Siguiente Cargo:{" "}
            {moment()
              .add(purchase.free_trial_length, "days")
              .format("DD MMM YYYY")}
          </p>
        </div>
      );
    }
    if (purchase.free_trial_end) {
      return (
        <div>
          <p>Total Pagado: $0 MXN</p>
          <p>
            Siguiente Cargo:{" "}
            {moment(purchase.free_trial_end).utc().format("DD MMM YYYY")}
          </p>
        </div>
      );
    }
    return <p>Total: ${formatMonto(purchase.amount)} MXN</p>;
  };

  const renderClassPackageTitle = () => {
    if (purchase.class_package && purchase.class_package !== null) {
      return purchase.class_package.title;
    }
  };

  const renderBilling = () => {
    if (purchase.class_package && purchase.class_package !== null) {
      const { is_subscription, subscription_period, subscription_interval } =
        purchase.class_package;
      let period = subscription_period;
      let interval = subscription_interval;
      if (is_subscription) {
        if (subscription_interval > 1) {
          period =
            subscription_period === "month"
              ? "meses"
              : subscription_period === "year"
              ? "años"
              : "días";
        } else {
          period =
            subscription_period === "month"
              ? "mes"
              : subscription_period === "year"
              ? "año"
              : "día";
        }
      }
      let frequency = "cada ";
      if (interval === 1) {
        interval = "";
      }
      return `${frequency} ${interval} ${period}`;
    }
  };

  const renderOrden = () => {
    if (purchase && purchase !== null) {
      return (
        <div>
          <h6>Orden #{purchase.purchase_id}</h6>
          <div className="mb-4">
            <h3>Plan: {renderClassPackageTitle()}</h3>
            <p>
              Fecha: {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}
            </p>
            {renderTotal()}
            <p>
              ${formatMonto(purchase.amount)} MXN {renderBilling()}
            </p>
          </div>
          <p>Método de Pago: {purchase.payment_method.name}</p>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="mvh-100 container-fluid text-white py-5">
      <div className="container login-card">
        <img
          src={`${S3_ENDPOINT}/logo-blanco.png`}
          alt="WallGo Logo"
          className="mw-100 w-100 d-block m-auto mb-4"
        />
        <div className="card br-10 no-scale mw-500 m-auto d-block p-4">
          <h1 className="text-center">¡Gracias por tu compra!</h1>
          <p className="text-center pb-3 border-bottom">
            A partir del 1 de Enero podrás disfrutar de tus workouts.
          </p>
          {renderOrden()}
          <Link to="/myfactory" className="btn btn-primary bold d-block">
            Comenzar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
