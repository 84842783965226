import React, { useReducer, createContext } from "react";
import MerchantReducer from "../reducers/MerchantReducer";
import MerchantsService from "../services/MerchantService";
import {
  MERCHANTS_RECEIVED,
  SET_MERCHANT,
  SINGLE_MERCHANT_RECEIVED,
} from "../types/merchants";

const initialState = {
  merchant: null,
  merchants: null,
};

export const MerchantContext = createContext(initialState);

export const MerchantProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MerchantReducer, initialState);

  const getMerchants = () => {
    MerchantsService.getAllMerchants().then((res) => {
      const { merchants } = res.data;
      dispatch({ type: MERCHANTS_RECEIVED, payload: merchants });
    });
  };

  const getSingleMerchant = (merchant_id) => {
    MerchantsService.getSingleMerchant(merchant_id).then((res) => {
      const { merchant } = res.data;
      dispatch({ type: SINGLE_MERCHANT_RECEIVED, payload: merchant });
    });
  };

  const setMerchant = (merchant) => {
    dispatch({ type: SET_MERCHANT, payload: merchant });
  };

  return (
    <MerchantContext.Provider
      value={{ ...state, setMerchant, getMerchants, getSingleMerchant }}
    >
      {children}
    </MerchantContext.Provider>
  );
};
