import { SET_MODE, SET_SELECTED } from "../types/menu";

const MenuReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_MODE:
      return { ...state, mode: payload };
    case SET_SELECTED:
      return { ...state, selected: payload };
    default:
      return { ...state };
  }
};
export default MenuReducer;
