import React from "react";
import mastercard from "../../assets/images/payment-method-mc.png";
import visa from "../../assets/images/payment-method-visa.png";
import amex from "../../assets/images/payment-method-amex.png";

const PaymentSourceCard = ({
  selected,
  hideCheck,
  hideButtons,
  handleDelete,
  payment_source,
  selectPaymentSource,
}) => {
  return (
    <div
      key={payment_source.payment_source_id}
      className="card shadow-sm p-3 no-scale mb-3"
    >
      <div className="row mx-0">
        {!hideCheck && (
          <div className="col-1">
            <input
              type="radio"
              checked={selected === payment_source.payment_source_id}
              onChange={() =>
                selectPaymentSource(payment_source.payment_source_id)
              }
            />
          </div>
        )}
        <div className="col-10">
          <div className="row align-items-center">
            <div className="col px-0 col-md-4">
              <img
                src={
                  payment_source.card_type === "mastercard"
                    ? mastercard
                    : payment_source.card_type === "visa"
                    ? visa
                    : amex
                }
                className="card-type"
                alt="card type"
              />
            </div>
            <div className="col col-md-4 capitalize">
              {payment_source.card_type}
            </div>
            <div className="col col-md-4">
              {"**** "}
              {payment_source.last_digits}
            </div>
          </div>
        </div>
        {!hideButtons && (
          <div className="col-1">
            <button
              className="btn btn-outline-danger"
              onClick={() => handleDelete(payment_source)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentSourceCard;
