import api from "./api";

const route = "/wallets";

const WalletsService = {
  getWallets: () => api.get(route),
  getSingleWallet: (wallet_id) => api.get(`${route}/${wallet_id}`),
  postWallet: (wallet) => api.post(route, { ...wallet }),
  putWallet: (wallet) => api.put(route, { ...wallet }),
  postUserWallet: (wallet_id, user) =>
    api.post(`${route}/${wallet_id}/users`, { ...user }),
  postWalletPaymentSource: (wallet_id, payment_source_id) =>
    api.post(`${route}/${wallet_id}/payment_sources`, { payment_source_id }),
  deleteUserWallet: (wallet_id, user_id) =>
    api.delete(`${route}/${wallet_id}/users/${user_id}`),
  deleteWalletPaymentSource: (wallet_id, payment_source_id) =>
    api.delete(`${route}/${wallet_id}/payment_sources/${payment_source_id}`),
};
export default WalletsService;
