import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  CREATE_WALLET,
  SET_PROPERTY_WALLET,
  SET_WALLET,
  WALLETS_RECEIVED,
} from "../types/wallets";

const schema = {
  name: "",
};

const WalletsReducer = (state, { type, payload }) => {
  switch (type) {
    case WALLETS_RECEIVED:
      return { ...state, wallets: payload };
    case CREATE_WALLET:
      return { ...state, wallet: schema };
    case SET_WALLET:
      return { ...state, wallet: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case SET_PROPERTY_WALLET:
      const wallet = { ...state.wallet };
      const { key, value } = payload;
      wallet[key] = value;
      return { ...state, wallet };
    default:
      return { ...state };
  }
};
export default WalletsReducer;
