import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import GoogleMapsWrapper from "./GoogleMapsWrapper";
import { googleMapStyles } from "./googlemapstyles";

const MarkersMap = ({ zoom, markers, base_url, selectedMarker }) => {
  const [selected, setSelected] = useState({
    lat: 25.6487718,
    lng: -100.3854967,
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setSelected(pos);
      });
    }
  }, []);

  const getCenter = () => {
    if (typeof selectedMarker === "object" && selectedMarker !== null) {
      return {
        lat: parseFloat(selectedMarker.lat),
        lng: parseFloat(selectedMarker.lng),
      };
    }
    if (typeof selected === "object" && selected !== null) {
      return {
        lat: parseFloat(selected.lat),
        lng: parseFloat(selected.lng),
      };
    }
    return { lat: 25, lng: 100 };
  };

  const isSelected = (marker) => {
    if (selectedMarker && selectedMarker !== null) {
      return (
        selectedMarker.lat === marker.lat && selectedMarker.lng === marker.lng
      );
    }
  };

  const renderMarkers = () => {
    if (Array.isArray(markers)) {
      return markers.map((marker, index) => (
        <Marker
          key={index}
          icon={{
            url: `${base_url}/marker${isSelected(marker) ? "-dark" : ""}.svg`,
          }}
          position={{
            lat: parseFloat(marker.lat),
            lng: parseFloat(marker.lng),
          }}
        />
      ));
    }
  };

  return (
    <GoogleMap
      zoom={zoom ? zoom : 12}
      center={getCenter()}
      options={{
        styles: [
          {
            featureType: "all",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          ...googleMapStyles,
        ],
        zoomControl: false,
        scaleControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
      }}
      mapContainerClassName="map-container"
    >
      {renderMarkers()}
    </GoogleMap>
  );
};

const GoogleMarkersMap = ({ selectedMarker, base_url, markers, zoom }) => (
  <GoogleMapsWrapper>
    <MarkersMap
      zoom={zoom}
      markers={markers}
      base_url={base_url}
      selectedMarker={selectedMarker}
    />
  </GoogleMapsWrapper>
);

export default GoogleMarkersMap;
