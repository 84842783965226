import React from "react";
import { S3_ENDPOINT, getLicensePlate } from "../../utils";

const VehicleCard = ({ selected, vehicle, onClick, handleEdit }) => {
  const renderImage = () => {
    if (vehicle.file && vehicle.file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${vehicle.file.name}.${vehicle.file.type}`}
          className="mw-100 w-100 d-block m-auto"
        />
      );
    }
    return <i className="fa fa-car" />;
  };

  const renderLicensePlate = () => {
    return getLicensePlate(vehicle);
  };
  return (
    <div
      className={`card shadow-sm p-3 no-scale my-2 ${
        selected === vehicle.vehicle_id ? "bg-primary text-white" : ""
      }`}
      onClick={(e) => {
        if (typeof onClick === "function") {
          onClick(vehicle);
        }
      }}
    >
      <div className="row align-items-center">
        <div className="col-3">
          <div className="icon-wrapper bg-light border">{renderImage()}</div>
        </div>
        <div className="col-9">
          <div className="row">
            <div className="col-8 col-md-10">
              <h3 className="mb-1">{vehicle.name}</h3>
              <p className="mb-1">{vehicle.make}</p>
              <p className="mb-0">
                <span className="small me-2">{renderLicensePlate()}</span>
                <span className="small">
                  {vehicle.year} - {vehicle.model}
                </span>
              </p>
            </div>
            <div className="col-4 col-md-2 text-end">
              {typeof handleEdit === "function" && (
                <button
                  onClick={(e) => {
                    handleEdit(vehicle);
                  }}
                  className="btn btn-sm btn-outline-primary"
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleCard;
