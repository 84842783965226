import React, { useContext, useEffect, useState } from "react";
import ChargesFilters from "../components/charges/ChargesFilters";
import { FiltersContext } from "../context/FiltersContext";
import { ChargesContext } from "../context/ChargesContext";
import ChargeCard from "../components/charges/ChargeCard";
import Pagination from "../components/global/Pagination";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";
import { getValue } from "../utils";

const Charges = () => {
  const [page, setPage] = useState(1);
  const { setSelected } = useContext(MenuContext);
  const { modalComponent } = useContext(ModalContext);
  const { filters, setFilter } = useContext(FiltersContext);
  const { charges, getCharges } = useContext(ChargesContext);

  useEffect(() => {
    setSelected("My Payments");
  }, []);

  useEffect(() => {
    getCharges({ ...filters, page });
  }, [filters, page]);

  const handleFilters = () => {
    modalComponent("Filtros", <ChargesFilters />);
  };

  const renderCharges = () => {
    if (Array.isArray(charges)) {
      if (charges.length === 0) {
        if (Object.keys(filters).length > 1 || page > 1) {
          return <p>There are no payments that match your search.</p>;
        }
        return <p>You haven't made any payments yet.</p>;
      }
      return charges.map((charge) => (
        <ChargeCard key={charge.charge_id} charge={charge} />
      ));
    }
  };
  return (
    <div className="container py-3 px-0">
      <div className="row align-items-center mb-3">
        <div className="col-10">
          <input
            type="text"
            placeholder="Buscar comercio..."
            className="form-control"
            value={getValue(filters, "query")}
            onChange={(e) => setFilter("query", e.target.value)}
          />
        </div>
        <div className="col-2">
          <button onClick={handleFilters} className="btn btn-primary">
            <i className="fas fa-sliders-h"></i>
          </button>
        </div>
      </div>
      {renderCharges()}
      <Pagination modifier={setPage} />
    </div>
  );
};

export default Charges;
