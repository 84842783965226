export const getMinPrice = (merchant) => {
  let min_price = null;
  if (merchant && merchant !== null) {
    const { products } = merchant;
    if (Array.isArray(products)) {
      products.forEach((product) => {
        if (min_price === null) {
          min_price = parseFloat(product.price);
        } else if (parseFloat(product.price) < parseFloat(min_price)) {
          min_price = parseFloat(product.price);
        }
      });
    }
  }
  return min_price;
};
