import React from "react";
import { S3_ENDPOINT, formatMonto } from "../../utils";
import { Link } from "@reach/router";
import { getMinPrice } from "../../utils/merchant";

const MerchantMapCard = ({ merchant, setMerchant }) => {
  const getSrc = () => {
    let src = "logo.png";
    if (merchant.file && merchant.file !== null) {
      return `${merchant.file.name}.${merchant.file.type}`;
    }
    return src;
  };

  const src = getSrc();

  const renderPrice = () => {
    const min_price = getMinPrice(merchant);
    if (min_price !== null) {
      return <p className="small mb-0">Desde ${formatMonto(min_price)} MXN</p>;
    }
  };

  return (
    <div
      onClick={(e) => {
        if (typeof setMerchant === "function") {
          setMerchant(merchant);
        }
      }}
      className={`merchant-map-card card p-3 d-inline-block mb-3 me-2 ${
        typeof setMerchant === "function" ? "cursor-pointer" : ""
      }`}
    >
      <div className="row px-1 mb-3 align-items-center">
        <div className="col-8">
          <h3 className="bold">{merchant.name}</h3>
          <h4 className="mb-0">Horario</h4>
          <p className="mb-0">
            {merchant.opening_time} - {merchant.closing_time}
          </p>
        </div>
        <div className="col-4 text-center">
          <img
            alt={merchant.name}
            src={`${S3_ENDPOINT}/${src}`}
            style={{ maxHeight: 50, width: "auto", objectFit: "contain" }}
          />
          {renderPrice()}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Link
            to={`/merchant/${merchant.merchant_id}`}
            className="btn btn-outline-primary w-100"
          >
            <i className="fa fa-info-circle me-1"></i> Details
          </Link>
        </div>
        <div className="col-6">
          <button className="btn btn-primary w-100">
            <i className="fa fa-directions me-2"></i>Directions
          </button>
        </div>
      </div>
    </div>
  );
};

export default MerchantMapCard;
