import React, { createContext, useContext, useReducer } from "react";
import WalletsReducer from "../reducers/WalletsReducer";
import WalletsService from "../services/WalletsService";
import {
  SET_WALLET,
  WALLETS_RECEIVED,
  SET_PROPERTY_WALLET,
  CREATE_WALLET,
} from "../types/wallets";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  wallets: null,
  wallet: null,
  spinner: false,
};

export const WalletsContext = createContext(initialState);

export const WalletsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WalletsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getWallets = () => {
    WalletsService.getWallets().then((res) => {
      const { wallets } = res.data;
      dispatch({ type: WALLETS_RECEIVED, payload: wallets });
    });
  };

  const getSingleWallet = (wallet_id) => {
    WalletsService.getSingleWallet(wallet_id).then((res) => {
      const { wallet } = res.data;
      dispatch({ type: SET_WALLET, payload: wallet });
    });
  };

  const createWallet = () => {
    dispatch({ type: CREATE_WALLET });
  };

  const setPropertyWallet = (key, value) => {
    dispatch({ type: SET_PROPERTY_WALLET, payload: { key, value } });
  };

  const postWalletPaymentSource = (wallet_id, payment_source_id) => {
    dispatch({ type: SHOW_SPINNER });
    WalletsService.postWalletPaymentSource(wallet_id, payment_source_id)
      .then(() => {
        success("Método de pago guardado.");
        dispatch({ type: HIDE_SPINNER });
        getSingleWallet(wallet_id);
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const saveUserWallet = (wallet_id, user) => {
    dispatch({ type: SHOW_SPINNER });
    WalletsService.postUserWallet(wallet_id, user)
      .then(() => {
        success(`${user.email} invitado por email.`);
        dispatch({ type: HIDE_SPINNER });
        getSingleWallet(wallet_id);
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteUserWallet = (wallet_id, user_id) => {
    dispatch({ type: SHOW_SPINNER });
    WalletsService.deleteUserWallet(wallet_id, user_id)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        success("Usuario eliminado.");
        getSingleWallet(wallet_id);
        clearModal();
      })
      .catch((error) => {
        dispatch({ type: SHOW_SPINNER });
        alert(error);
      });
  };

  const deleteWalletPaymentSource = (wallet_id, payment_source_id) => {
    dispatch({ type: SHOW_SPINNER });
    WalletsService.deleteWalletPaymentSource(wallet_id, payment_source_id)
      .then(() => {
        success("Método de pago guardado.");
        dispatch({ type: HIDE_SPINNER });
        getSingleWallet(wallet_id);
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const saveWallet = (wallet) => {
    dispatch({ type: SHOW_SPINNER });
    let service = WalletsService.putWallet;
    if (isNaN(parseInt(wallet.wallet_id))) {
      service = WalletsService.postWallet;
    }
    service(wallet)
      .then(() => {
        success("Wallet guardada.");
        dispatch({ type: HIDE_SPINNER });
        if (!isNaN(parseInt(wallet.wallet_id))) {
          getSingleWallet(wallet.wallet_id);
        } else {
          clearModal();
          getWallets();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <WalletsContext.Provider
      value={{
        ...state,
        getWallets,
        saveWallet,
        createWallet,
        saveUserWallet,
        getSingleWallet,
        deleteUserWallet,
        setPropertyWallet,
        postWalletPaymentSource,
        deleteWalletPaymentSource,
      }}
    >
      {children}
    </WalletsContext.Provider>
  );
};
