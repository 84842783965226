import React from "react";
import Page from "../../components/global/Page";

const PreguntasFrecuentes = () => {
  return (
    <Page title="Preguntas Frecuentes">
      <h4>¿Puedo pagar con tarjeta de crédito?</h4>
      <p>Sí, el método de pago predeterminado es tarjeta de crédito.</p>
    </Page>
  );
};

export default PreguntasFrecuentes;
