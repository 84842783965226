import { formatMonto } from "../../utils";
import StatusBadge from "../global/StatusBadge";
import moment from "moment";
import React from "react";

const SingleChargeCard = ({ charge, hideButton }) => {
  return (
    <div className="card p-3 mb-3 shadow-sm">
      <div className="row align-items-center">
        <div className="col-10">
          <div className="row align-items-center">
            <div className="col-3">
              <p className="h3 mb-0">${formatMonto(charge.amount)}</p>
            </div>
            <div className="col-9">
              <p className="mb-1">
                {moment(charge.createdAt).format("DD MMM YYYY HH:mm")}
              </p>
              <StatusBadge status={charge.status} />
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          {!hideButton && (
            <button className="btn btn-sm btn-outline-secondary">
              <i className="fa fa-info-circle"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleChargeCard;
