import moment from "moment";
import React from "react";
import WalletCard from "./WalletCard";

const WalletContainer = ({ wallets }) => {
  const renderWallets = () => {
    if (Array.isArray(wallets)) {
      wallets = wallets.sort((a, b) => (moment(a).isAfter(moment(b)) ? -1 : 1));
      if (wallets.length === 0) {
        return <p className="px-1">No wallets found.</p>;
      }
      return (
        <div
          className="wallet-wrapper"
          style={{ width: `${wallets.length * 370}px` }}
        >
          <div className="row mx-0 pb-3">
            {wallets.map((wallet) => (
              <WalletCard hideButton key={wallet.wallet_id} wallet={wallet} />
            ))}
          </div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="mb-2">
      <h2>Wallets</h2>
      <div className="wallet-container">{renderWallets()}</div>
    </div>
  );
};

export default WalletContainer;
