import React, { useContext } from "react";
import { MenuContext } from "../../context/MenuContext";
import { AuthContext } from "../../context/AuthContext";
import OffcanvasHeader from "./OffcanvasHeader";
import OffcanvasFooter from "./OffcanvasFooter";
import NavbarLink from "./NavbarLink";

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const { menus } = useContext(MenuContext);

  const RenderMenu = () => {
    let menusRender = menus;
    menusRender = menusRender.filter(
      (menu) =>
        (!menu.level || menu.level <= user.user_type_id) &&
        (!menu.base_url || window.location.pathname.includes(menu.base_url))
    );

    return (
      <div className="h-100">
        {menusRender.map((menu) => (
          <div className="h-100 d-flex flex-column" key={menu.name}>
            <div className="row">
              {menu.items.map((item) => (
                <div className="col-6 mb-3 item-button" key={item.name}>
                  <NavbarLink scoped={menu.scoped} {...item} isSidebar />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div
      aria-labelledby="staticBackdropLabel"
      className="offcanvas offcanvas-start"
      data-bs-backdrop="static"
      id="staticBackdrop"
      tabIndex="-1"
    >
      <OffcanvasHeader />
      <div className="offcanvas-body pt-0">
        {Array.isArray(menus) && user !== null && <RenderMenu />}
        <div className="d-flex justify-content-between mt-auto"></div>
      </div>
      <OffcanvasFooter />
    </div>
  );
};

export default Sidebar;
