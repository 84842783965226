import React, { useContext, useState, useEffect } from "react";
import { cardStyle } from "../../utils";
import { STRIPE_KEY } from "../../utils";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { AuthContext } from "../../context/AuthContext";
import { PaymentSourcesContext } from "../../context/PaymentSourceContext";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const promise = loadStripe(STRIPE_KEY);

const StripeCreditCardForm = ({
  user,
  processing,
  submitCallback,
  handleChange,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (processing) {
      handleSubmit();
    }
  }, [processing]);

  const handleSubmit = async () => {
    const card = elements.getElement(CardElement);
    const payment_method = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        email: user.email,
      },
    });
    if (typeof submitCallback === "function") {
      submitCallback(payment_method);
    }
  };

  return (
    <CardElement
      id="card-element"
      options={cardStyle}
      className="form-control pt-2 my-2"
      onChange={handleChange}
    />
  );
};

const PaymentSourceForm = ({ handleCancel, submitCallback }) => {
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { user } = useContext(AuthContext);

  const { postPaymentSource } = useContext(PaymentSourcesContext);

  const handleChange = async (event) => {
    setDisabled(event.empty);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Tarjeta de Crédito/Débito</label>
      <Elements stripe={promise}>
        <StripeCreditCardForm
          user={user}
          processing={processing}
          handleChange={handleChange}
          submitCallback={
            typeof submitCallback === "function"
              ? submitCallback
              : postPaymentSource
          }
        />
      </Elements>
      <button
        type="submit"
        disabled={processing || disabled}
        className="btn btn-primary w-100 mb-4"
      >
        Guardar
      </button>
      <button
        type="button"
        onClick={handleCancel}
        disabled={processing || disabled}
        className="btn btn-link text-secondary w-100"
      >
        Cancelar
      </button>
    </form>
  );
};

export default PaymentSourceForm;
