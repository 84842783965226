import moment from "moment";
import { Capacitor } from "@capacitor/core";

moment.locale("es", {
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
});

export const GOOGLE_MAPS_KEY = "AIzaSyBnngxJGKf-SupSroc6REhIsUVIrBxxB8U";

export const stripe_test_key =
  "pk_test_51MeoKzCiAI48ZxOCmqvjA6lrcQx3rTpJw2bsSL98DXkB06sYHBIZS7DPd0yXJuhGauATY9it7EdNoGOAANx06fxi00TFCOYmqt";

export const stripe_live_key =
  "pk_test_51MeoKzCiAI48ZxOCmqvjA6lrcQx3rTpJw2bsSL98DXkB06sYHBIZS7DPd0yXJuhGauATY9it7EdNoGOAANx06fxi00TFCOYmqt";

export const STRIPE_KEY =
  process.env.NODE_ENV === "development" ? stripe_test_key : stripe_live_key;

export const BASE_URL =
  (process.env.NODE_ENV === "development" && Capacitor.getPlatform() === "web"
    ? "http://localhost:4000"
    : "https://wallgoapp.com") + "/api";

export const S3_ENDPOINT = "https://wallgoapp.s3.us-west-1.amazonaws.com";

export const searchRows = (query, rows) => {
  if (!rows) return;
  if (isNaN(query)) query = query.toLowerCase();
  let searchResult = rows.filter((row) => {
    let result = Object.keys(row).filter((column) => {
      if (Array.isArray(row[column])) {
        return row[column].filter((subcolumn) => {
          if (isNaN(subcolumn)) {
            if (subcolumn.toLowerCase().includes(query)) return row;
          } else if (subcolumn === query) return row;
          return null;
        });
      }
      if (isNaN(row[column])) {
        if (String(row[column]).toLowerCase().includes(query)) {
          return row;
        }
      } else if (String(row[column]) === query) {
        return row;
      } else if (Number(row[column]) === Number(query)) {
        return row;
      }
      return null;
    });
    return result.length > 0;
  });
  return searchResult;
};

export const getArgs = (args) => {
  if (args && args !== null) {
    const array = Object.keys(args)
      .map((key) => {
        if (args[key] && args[key] !== null && args[key] !== "") {
          return `${key}=${args[key]}`;
        }
        return null;
      })
      .filter((arg) => arg !== null);
    if (array.length > 0) {
      return `${array.join("&")}`;
    }
  }
  return "";
};

export const calcularTotal = (productos) => {
  if (productos && productos !== null) {
    let total = 0;
    productos.forEach((producto) => {
      total += producto.precio * producto.cantidad;
    });
    return total;
  }
  return 0;
};

export function formatMonto(monto) {
  monto = parseFloat(monto);
  if (!monto || monto === null || isNaN(monto)) monto = 0;
  return numberWithCommas(monto);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const hideModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  }
};

export const showModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#modal";
    newButton.id = "main-button";
    newButton.setAttribute("data-toggle", "modal");
    newButton.setAttribute("data-target", "#modal");
    newButton.setAttribute("data-bs-toggle", "modal");
    newButton.setAttribute("data-bs-target", "#modal");
    newButton.style.visibility = "hidden";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const misclases = [
  {
    name: "Online",
    handle: "/myfactory/online",
  },
  {
    name: "Presenciales",
    handle: "/myfactory/presenciales",
  },
];

export const shop = [
  {
    name: "Clases",
    handle: "/myfactory/shop",
  },
  {
    name: "Merch",
    handle: "/myfactory/merch",
  },
];

export const cuenta = [
  {
    name: "Mi Información",
    handle: `/myfactory/informacion`,
  },
  {
    name: "Mis Métodos de Pago",
    handle: `/myfactory/metodos-pago`,
  },
  {
    name: "Mis Compras",
    handle: `/myfactory/pagos`,
  },
];

export const cardStyle = {
  style: {
    base: {
      color: "#f8f9fa",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#f8f9fa",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCardType = (number) =>
  String(number) === "3"
    ? "amex"
    : String(number) === "4"
    ? "visa"
    : "mastercard";

export const categories = {
  dance: [11, 12, 13, 15],
  workout: [14, 15],
  presenciales: [11742, 11743, 11744, 11745],
};

export const getCompradasPresenciales = (paquetes) => {
  const package_ids = categories.presenciales;
  const customer_classes = paquetes.filter((class_category) =>
    package_ids.includes(class_category.package_class_id)
  );
  let total = 0;
  customer_classes.forEach((purchase) => {
    total += purchase.available_class;
  });
  return total;
};

export const getVigencia = (handle, user) => {
  const paquete = user.vigencias.find(
    (class_category) => class_category.handle === handle
  );
  if (paquete) return paquete.expiration;
};

export const isManager = (user) => {
  return user.isManager !== null && user.isManager;
};

export const isAdmin = (user) => {
  return user.isAdmin !== null && user.isAdmin;
};

export const isCoach = (user) => {
  return user.instructor_id !== null && user.instructor_id;
};

export const getLocalMoment = (datetime) => {
  return moment(
    moment(datetime).utc().format("YYYY-MM-DD HH:mm:ss"),
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const getClassAvailableSpaces = (singleClass) => {
  return singleClass.capacity - singleClass.class_reservations.length;
};

export const userInformatinCompleted = (user) => {
  let valid = true;
  if (String(user.phone).length < 10) valid = false;
  if (user.last_name === "" || user.last_name === null) valid = false;
  return valid;
};

export const getLocalMomentDiff = (datetime, unitOfTime) => {
  return moment().diff(getLocalMoment(datetime), unitOfTime);
};

export const hasNotch = (device) => {
  let current = String(device);
  if (current.includes("iPhone")) {
    return (
      current.includes("X") ||
      current.includes("11") ||
      current.includes("12") ||
      current.includes("13") ||
      current.includes("14") ||
      current.includes("15")
    );
  }
};

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const getBilling = (class_package) => {
  const { is_subscription, subscription_period, subscription_interval } =
    class_package;
  let period = subscription_period;
  let interval = subscription_interval;
  if (is_subscription) {
    if (subscription_interval > 1) {
      period =
        subscription_period === "month"
          ? "meses"
          : subscription_period === "year"
          ? "años"
          : "días";
    } else {
      period =
        subscription_period === "month"
          ? "mes"
          : subscription_period === "year"
          ? "año"
          : "día";
    }
  }
  let frequency = "cada ";
  if (interval === 1) {
    interval = "";
  }
  return `${frequency} ${interval} ${period}`;
};

export const showOffcanvas = () => {
  let offcanvas = document.getElementsByClassName("offcanvas")[0];
  if (offcanvas && offcanvas !== null) {
    offcanvas.classList.add("show");
  }
};

export const hideOffcanvas = () => {
  let offcanvas = document.getElementsByClassName("offcanvas")[0];
  let backdrop = document.getElementsByClassName("offcanvas-backdrop")[0];
  if (offcanvas && offcanvas !== null) {
    offcanvas.classList.remove("show");
    document.body.style = "";
    if (backdrop && backdrop !== null) {
      backdrop.remove();
    }
  }
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getLicensePlate = (vehicle) => {
  let { vehicle_registrations } = vehicle;
  if (Array.isArray(vehicle_registrations)) {
    const vehicle_registration = vehicle_registrations[0];
    if (
      vehicle_registration &&
      vehicle_registration !== null &&
      typeof vehicle_registration === "object"
    ) {
      return vehicle_registration.license_plate;
    }
  }
  return "";
};

export const addFormEventListeners = () => {
  let inputGroups = document.getElementsByClassName("input-group");
  for (let i = 0; i < inputGroups.length; i++) {
    let currentInputGroup = inputGroups[i];
    let children = currentInputGroup.children;
    let input = children[1];
    if (input && input !== null) {
      input.addEventListener("focus", function () {
        currentInputGroup.classList.add("border-primary");
      });
      input.addEventListener("blur", function () {
        currentInputGroup.classList.remove("border-primary");
      });
    }
  }
};

export const getAddress = (location) => {
  if (location === null) return "";
  if (location.show_exact_location) {
    return `${location.street} ${location.number} ${location.neighborhood}, ${location.city}`;
  }
  if (location.neighborhood !== "" && location.city !== "") {
    return `${location.city}`;
  }
  return "";
};

export const getValue = (object, key, boolean) => {
  if (object && object !== null) {
    const value = object[key];
    if (value && value !== null) {
      if (boolean) return value === true || value === 1;
      return value;
    }
  }
  if (boolean) return false;
  return "";
};

export const backgrounds = [
  {
    file_id: 1,
    name: "wallpaper-1",
    type: "jpg",
  },
  {
    file_id: 2,
    name: "wallpaper-2",
    type: "jpg",
  },
  {
    file_id: 3,
    name: "wallpaper-3",
    type: "jpg",
  },
  {
    file_id: 4,
    name: "wallpaper-4",
    type: "jpg",
  },
  { file_id: 5, name: "wallpaper-5", type: "jpg" },
  { file_id: 6, name: "wallpaper-6", type: "jpg" },
];
