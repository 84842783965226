import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import AuthService from "../services/AuthService";
import { navigate } from "@reach/router";
import { Link } from "@reach/router";

const LoginPhone = () => {
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [recaptcha, setRecaptcha] = useState(null);

  const { user, signInPhone, signInWithPhoneNumber, confirmationResult } =
    useContext(AuthContext);

  useEffect(() => {
    if (recaptcha === null) {
      setupRecaptcha();
    }
  }, []);

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user]);

  const handleSubmitCode = (e) => {
    e.preventDefault();
    confirmationResult
      .confirm(code)
      .then((result) => {
        console.log(result.user);
        signInPhone();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signInWithPhoneNumber(phone, recaptcha);
  };

  const setupRecaptcha = () => {
    const recaptchaVerifier = AuthService.getRecaptchaVerifier("sign-in");
    setRecaptcha(recaptchaVerifier);
  };

  const renderForm = () => {
    if (confirmationResult === null) {
      return (
        <form className="card shadow-sm" onSubmit={handleSubmit}>
          <label className="d-block mb-2">Teléfono</label>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <i className="fa fa-phone text-primary"></i>
            </span>
            <input
              type="text"
              value={phone}
              placeholder="Teléfono"
              className="form-control"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="container-fluid px-0 mb-2">
            <button
              id="sign-in"
              disabled={recaptcha === null}
              className="btn btn-primary w-100 d-block m-auto my-4"
            >
              Login
            </button>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <Link
                to="/recovery"
                className="text-accent h6 text-center d-block w-100"
              >
                ¿Olvidaste tu contraseña?
              </Link>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <Link
                to="/auth/signup/phone"
                className="text-primary h6 text-center d-block w-100"
              >
                Crea tu cuenta
              </Link>
            </div>
          </div>
        </form>
      );
    }
    return (
      <form className="card shadow-sm" onSubmit={handleSubmitCode}>
        <label className="d-block mb-2">Ingresa el Código</label>
        <input
          type="text"
          value={code}
          placeholder="Teléfono"
          className="form-control"
          onChange={(e) => setCode(e.target.value)}
        />
        <div className="container-fluid px-0 mt-3">
          <button className="btn btn-primary w-100 d-block m-auto">
            Confirmar
          </button>
        </div>
      </form>
    );
  };

  return (
    <div className="container-fluid py-3">
      <h1 className="text-center">WallGo</h1>
      {renderForm()}
    </div>
  );
};

export default LoginPhone;
