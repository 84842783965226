import React, { useState, useContext, useEffect } from "react";
import PaymentSourceList from "../components/payment_sources/PaymentSourceList";
import PaymentSourceForm from "../components/payment_sources/PaymentSourceForm";
import { PaymentSourcesContext } from "../context/PaymentSourceContext";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import UserWalletList from "../components/user/UserWalletList";
import ChargesList from "../components/charges/ChargesList";
import { WalletsContext } from "../context/WalletsContext";
import InviteUsers from "../components/user/InviteUsers";
import { ModalContext } from "../context/ModalContext";
import WalletCard from "../components/wallet/WalletCard";
import { MenuContext } from "../context/MenuContext";

const SingleWallet = ({ wallet_id }) => {
  const [view, setView] = useState("charges");
  const { wallet, saveUserWallet, getSingleWallet, postWalletPaymentSource } =
    useContext(WalletsContext);
  const { postPaymentSource } = useContext(PaymentSourcesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    getSingleWallet(wallet_id);
    setSelected("Wallet Detail");
  }, []);

  const handleCallback = (payment_source) => {
    postPaymentSource(payment_source, handlePaymentSource);
  };

  const handlePaymentSource = (payment_source_id) => {
    postWalletPaymentSource(wallet_id, payment_source_id);
  };

  const handleCreate = () => {
    if (view === "cards") {
      return modalComponent(
        "Agregar Método",
        <PaymentSourceForm
          handleCancel={clearModal}
          submitCallback={handleCallback}
        />
      );
    }
    modalComponent(
      "Invitar Usuarios",
      <InviteUsers
        handleCancel={clearModal}
        handleSubmitUser={(user) => saveUserWallet(wallet_id, user)}
      />
    );
  };

  const renderContent = () => {
    if (wallet && wallet !== null) {
      switch (view) {
        case "users":
          return (
            <UserWalletList wallet_id={wallet_id} users={wallet.user_wallets} />
          );
        case "cards":
          return (
            <PaymentSourceList
              wallet_id={wallet_id}
              payment_sources={wallet.payment_sources}
            />
          );
        default:
          return <ChargesList wallet_id={wallet_id} charges={wallet.charges} />;
      }
    }
  };

  const renderWallet = () => {
    if (wallet && wallet !== null) {
      return (
        <IonSegment value={view} className="mb-3">
          <IonSegmentButton value="charges" onClick={() => setView("charges")}>
            <IonLabel>Cargos</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="cards" onClick={() => setView("cards")}>
            <IonLabel>Tarjetas</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="users" onClick={() => setView("users")}>
            <IonLabel>Usuarios</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderButton = () => {
    if (view !== "charges") {
      return (
        <div className="container fixed-bottom p-3">
          <button className="btn btn-primary w-100 bold" onClick={handleCreate}>
            + Agregar
          </button>
        </div>
      );
    }
  };

  return (
    <div className="container">
      <div className="py-4">
        {wallet && wallet !== null && <WalletCard wallet={wallet} />}
      </div>
      {renderWallet()}
      {renderContent()}
      {renderButton()}
    </div>
  );
};

export default SingleWallet;
