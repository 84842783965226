import React, { useContext, useEffect } from "react";
import TicketCard from "../components/tickets/TicketCard";
import { TicketsContext } from "../context/TicketsContext";

const ActiveTickets = () => {
  const { tickets, getMyTickets } = useContext(TicketsContext);

  useEffect(() => {
    getMyTickets();
  }, []);

  const getWidth = () => {
    if (Array.isArray(tickets)) {
      return `calc(105% * ${
        tickets.filter((ticket) => ticket.end_date === null).length
      })`;
    }
  };

  const renderTickets = () => {
    if (Array.isArray(tickets)) {
      let ticketsRender = tickets.filter((ticket) => ticket.end_date === null);
      if (ticketsRender.length === 0) {
        return <p>You don't have any active tickets</p>;
      }
      return ticketsRender.map((ticket) => (
        <div className="ticket-wrapper my-3">
          <div
            style={{
              width: getWidth(),
            }}
          >
            <div key={ticket.ticket_id} className="ticket-card-wrapper">
              <TicketCard key={ticket.ticket_id} ticket={ticket} />
            </div>
          </div>
        </div>
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-0">
      <h2>Active Tickets</h2>
      {renderTickets()}
    </div>
  );
};

export default ActiveTickets;
