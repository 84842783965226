import React, { useEffect, useContext } from "react";
import { WalletsContext } from "../../context/WalletsContext";
import { VehiclesContext } from "../../context/VehiclesContext";
import { FiltersContext } from "../../context/FiltersContext";
import { getValue, getLicensePlate } from "../../utils";
import { ChargesContext } from "../../context/ChargesContext";
import { ModalContext } from "../../context/ModalContext";

const ChargesFilters = () => {
  const { merchants } = useContext(ChargesContext);
  const { clearModal } = useContext(ModalContext);
  const { wallets, getWallets } = useContext(WalletsContext);
  const { vehicles, getVehicles } = useContext(VehiclesContext);
  const { filters, setFilter, clearFilters } = useContext(FiltersContext);

  useEffect(() => {
    getVehicles();
    getWallets();
  }, []);

  const handleCancel = () => {
    clearFilters();
    clearModal();
  };

  const renderVehicles = () => {
    if (Array.isArray(vehicles)) {
      return [
        <option value="" key="">
          Todos
        </option>,
        ...vehicles.map((vehicle) => (
          <option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
            {getLicensePlate(vehicle)}
          </option>
        )),
      ];
    }
  };

  const renderMerchants = () => {
    if (Array.isArray(merchants)) {
      return [
        <option value="" key="">
          Todos
        </option>,
        ...merchants.map((merchant) => <option>{merchant.name}</option>),
      ];
    }
  };

  const renderWallets = () => {
    if (Array.isArray(wallets)) {
      return [
        <option value="" key="">
          Todos
        </option>,
        ...wallets.map((wallet) => (
          <option key={wallet.wallet_id} value={wallet.wallet_id}>
            {wallet.name}
          </option>
        )),
      ];
    }
  };
  return (
    <div>
      <div className="row mb-3">
        <div className="col-6">
          <label>Wallet</label>
          <select
            value={getValue(filters, "wallet_id")}
            className="form-control"
            onChange={(e) => setFilter("wallet_id", e.target.value)}
          >
            {renderWallets()}
          </select>
        </div>
        <div className="col-6">
          <label>Vehiculo</label>
          <select
            value={getValue(filters, "vehicle_id")}
            className="form-control"
            onChange={(e) => setFilter("vehicle_id", e.target.value)}
          >
            {renderVehicles()}
          </select>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label>Comercio</label>
          <select
            value={getValue(filters, "merchant_id")}
            className="form-control"
            onChange={(e) => setFilter("merchant_id", e.target.value)}
          >
            {renderMerchants()}
          </select>
        </div>
        <div className="col-6">
          <label>Estado</label>
          <select
            value={getValue(filters, "status")}
            className="form-control"
            onChange={(e) => setFilter("status", e.target.value)}
          >
            <option value="">Ver Todo</option>
            <option value="authorized">Por Cobrar</option>
            <option value="captured">Completado</option>
            <option value="cancelled">Fallido</option>{" "}
          </select>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label>Desde</label>
          <input
            type="date"
            value={getValue(filters, "start_date")}
            className="form-control"
            onChange={(e) => setFilter("start_date", e.target.value)}
          />
        </div>
        <div className="col-6">
          <label>Hasta</label>
          <input
            type="date"
            value={getValue(filters, "end_date")}
            className="form-control"
            onChange={(e) => setFilter("end_date", e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div onClick={clearModal} className="col-6">
          <button className="btn btn-primary w-100">Aplicar</button>
        </div>
      </div>
    </div>
  );
};

export default ChargesFilters;
