import React from "react";
import ChargeCard from "./ChargeCard";

const ChargesList = ({ charges }) => {
  const renderCharges = () => {
    if (Array.isArray(charges)) {
      if (charges.length === 0) {
        return <p>No has hecho ningún cargo.</p>;
      }
      let chargesRender = [...charges];
      if (chargesRender.length === 0) {
        return <p>No encontramos cargos para tu búsqueda.</p>;
      }
      return chargesRender.map((charge) => (
        <ChargeCard key={charge.charge_id} charge={charge} />
      ));
    }
  };

  return <div className="charge-list">{renderCharges()}</div>;
};

export default ChargesList;
