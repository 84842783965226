import React, { useContext, useEffect } from "react";
import VehicleCard from "../components/vehicles/VehicleCard";
import { VehiclesContext } from "../context/VehiclesContext";
import { MenuContext } from "../context/MenuContext";
import { navigate } from "@reach/router";
import { Link } from "@reach/router";

const Vehicles = () => {
  const { vehicles, getVehicles } = useContext(VehiclesContext);
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected("My Vehicles");
    getVehicles();
  }, []);

  const renderVehicles = () => {
    if (Array.isArray(vehicles)) {
      if (vehicles.length === 0) {
        return <p>You have not added any vehicles.</p>;
      }
      return vehicles.map((vehicle) => (
        <VehicleCard
          key={vehicle.vehicle_id}
          vehicle={vehicle}
          handleEdit={(vehicle) => navigate(`/vehicles/${vehicle.vehicle_id}`)}
        />
      ));
    }
  };
  return (
    <div className="container-fluid px-0 mb-3">
      {renderVehicles()}
      <div className="fixed-bottom container px-0 mb-3">
        <Link to="/vehicles/new" className="btn btn-primary w-100">
          + Add Vehicle
        </Link>
      </div>
    </div>
  );
};

export default Vehicles;
