import React from "react";
import Page from "../components/global/Page";

const Privacy = () => {
  return (
    <Page title="Aviso de Privacidad">
      <p>
        El presente documento constituye el “Aviso de Privacidad” de Wallgo y de
        sus filiales y subsidiarias, en donde se establece la manera en que será
        tratada su información por parte de WallGo, así como la finalidad para
        la que fue recabada, lo anterior de conformidad con los artículos 15 y
        16 de Ley Federal de Protección de Datos Personales en Posesión de los
        Particulares (la “Ley”).
      </p>
      <p>
        También describe qué tipo de datos son y qué opciones tiene para limitar
        el uso de dichos datos, así como el procedimiento para ejercer sus
        derechos, en cumplimiento a los principios de Licitud, Consentimiento,
        Calidad, Información, Finalidad, Lealtad, Proporcionalidad y
        Responsabilidad que la ley establece.
      </p>
      <p>
        WallGo respeta su derecho a la privacidad y protección de datos
        personales, datos personales sensibles, incluyendo sus datos financieros
        o patrimoniales, los cuales están amparados bajo la Ley. La base de
        datos en donde consta su información es responsabilidad de WallGo, la
        cual se encuentra ubicada en el estado Nuevo León, asimismo su
        información se encuentra debidamente resguardada conforme a las
        disposiciones de seguridad administrativa, técnica y física,
        establecidas en la Ley de la materia, para protegerla de los posibles
        daños, perdidas, alteración o acceso no autorizado.
      </p>
      <p>
        El Titular de la Información reconoce y acepta que en caso de que este
        “Aviso de Privacidad” esté disponible a través de una página electrónica
        (sitio web, página de Internet o similar) o algún otro dispositivo
        electrónico, al hacer clic en “aceptar” o de cualquier otra forma seguir
        navegando en el sitio, o bien al proporcionar sus Datos a través del
        mismo o a través de cualquier medio electrónico (correo electrónico,
        etc.), constituye una manifestación de su consentimiento para que WallGo
        realice el tratamiento de sus Datos, de conformidad con este Aviso de
        Privacidad.
      </p>
      <h4>Datos Sensibles</h4>
      <p>
        El Titular de la Información reconoce y acepta, que debido a su relación
        con WallGo no ha proporcionado, ni tendrá que proporcionar “datos
        personales sensibles”, es decir, aquellos datos personales íntimos o
        cuya realización debida o indebida pueda dar origen a discriminación o
        conlleve un riesgo grave para éste. En el supuesto de que el Titular de
        la Información proporcione datos del tipo de los llamados sensibles,
        deberá estar de acuerdo en proporcionarlos previamente y dejará a WallGo
        libre de cualquier queja o reclamación respectiva.
      </p>
      <h4>Solicitudes</h4>
      <p>
        El titular podrá ejercer los derechos de acceso, rectificación,
        cancelación u oposición, respecto a los datos personales que le
        conciernen, así como también solicitar la revocación de su
        consentimiento, enviando una solicitud a WallGo a través del correo
        hola@wallgoapp.com, indicando por lo menos su nombre y domicilio
        completo o cualquier otro dato o documento que permita su
        identificación, así como el objeto de su solicitud y/o trámite a
        efectuar. Lo anterior se deberá realizar en base a la Ley y su
        Reglamento.
      </p>
      <h4>Finalidad</h4>
      <p>
        Sus datos personales e historial como cliente serán utilizados por
        WallGo o por cualquiera de sus subsidiaras y/o filiales, y quedarán
        registrados en nuestra base de datos, así como cualquier otra
        información personal, sensible o de cualquier otra índole, siempre que
        se cumpla con lo establecido en la Ley y su Reglamento respecto a cada
        tipo de información. Esta información puede ser utilizada por WallGo
        para cualquiera de las siguientes finalidades:
      </p>
      <ul>
        <li>
          a. Finalidad Comercial
          <p>
            Nombre del Titular de la Información, domicilio, RFC, teléfono,
            número de cuenta de facturación, fecha de nacimiento, datos
            bancarios.
          </p>
          <p>
            Esta información podrá usarse de manera enunciativa mas no
            limitativa para: (1) ofrecerle productos y servicios, ya sea de
            manera física, telefónica, electrónica o por cualquier otra
            tecnología o medio que esté al alcance de WallGo; (2) hacer de su
            conocimiento nuevos productos o servicios que puedan ser de interés
            en relación con el servicio contratado; (3) realizar para su
            comodidad los cargos facturados de manera automática; (4) emitir
            órdenes de compra o solicitudes de trabajo, según corresponda; y/o
            (5) cualquier otra acción que sea necesaria para cumplir con los
            intereses de WallGo respecto al acuerdo que haya llegado con el
            Titular de la Información.
          </p>
        </li>
        <li>
          b. Finalidad Laboral
          <p>
            (i) En relación con ofertas o solicitudes de trabajo mediante la
            presentación de curriculum vitae, se podrá pedir información como
            nombre, domicilio, teléfono de contacto, correo electrónico, nivel
            de estudios, referencias personales, experiencia profesional, RFC,
            número de seguridad social, CURP, identificación oficial.
          </p>
          <p>
            Esta información podrá usarse de manera enunciativa mas no
            limitativa para: (1) evaluación como posible candidato para ocupar
            algún puesto vacante; (2) en su caso, la elaboración del Contrato de
            Prestación de Servicios y/o Contrato Individual de Trabajo
            correspondiente; (3) para proporcionar referencias en caso de que
            otra persona o empresa las solicite sobre candidatos.
          </p>
        </li>
      </ul>
      <h4>Transferencia</h4>
      <p>
        El Titular de la Información entiende y acepta que WallGo y/o cualquiera
        de sus subsidiarias y/o filiales, podrá transferir sus datos personales
        a terceros que han sido contratados por WallGo para que realicen en su
        nombre y representación ciertas tareas relacionadas con las actividades
        comerciales y de promoción de sus productos y/o servicios. Estas
        terceras partes pueden tratar los datos en cumplimiento de las
        instrucciones de WallGo, o tomar decisiones sobre ellos como parte de la
        prestación de sus servicios. En cualquiera de los dos casos, WallGo
        seleccionará proveedores que considere confiables y que se comprometan,
        mediante un contrato u otros medios legales aplicables, a implementar
        las medidas de seguridad necesarias para garantizar un nivel de
        protección adecuado a sus datos personales. Derivado de lo anterior,
        WallGo exigirá a sus proveedores que cumplan con medidas de seguridad
        que garanticen los mismos niveles de protección que WallGo implementa
        durante el tratamiento de sus datos como cliente de WallGo. Estas
        terceras partes seleccionadas tendrán acceso a su información con la
        finalidad de realizar las tareas especificadas en el contrato de
        servicios aplicable que haya suscrito con WallGo. Si WallGo determina
        que un proveedor no está cumpliendo con las obligaciones pactadas,
        tomará inmediatamente las acciones pertinentes.
      </p>
      <p>
        Si el titular, no acepta la transmisión de sus datos personales de
        conformidad con lo estipulado en el párrafo anterior, puede ponerse en
        contacto con WallGo, por cualquiera de los medios establecidos en el
        presente Aviso de Privacidad.
      </p>
      <h4>Excepciones</h4>
      <p>
        Adicionalmente y de conformidad con lo estipulado en los Artículos 10,
        37 y demás relativos de la Ley y su Reglamento, WallGo quedará
        exceptuado de las obligaciones referentes al consentimiento para el
        Tratamiento y Transferencia de sus Datos, cuando:
      </p>
      <ul>
        <li>Esté previsto en una Ley;</li>
        <li>Los datos figuren en fuentes de acceso público;</li>
        <li>
          Los datos personales se sometan a un procedimiento previo de
          disociación;
        </li>
        <li>
          Tenga el propósito de cumplir obligaciones derivadas de una relación
          jurídica entre el titular y el responsable;
        </li>
        <li>
          Exista una situación de emergencia que potencialmente pueda dañar a un
          individuo en su persona o en sus bienes;
        </li>
        <li>
          Sean indispensables para la atención médica, la prevención,
          diagnóstico, la prestación de asistencia sanitaria, tratamientos
          médicos o la gestión de servicios sanitarios;
        </li>
        <li>Se dicte resolución de autoridad competente;</li>
        <li>
          Cuando la transferencia sea precisa para el reconocimiento, ejercicio
          o defensa de un derecho en un proceso judicial, y
        </li>
        <li>
          Cuando la transferencia sea precisa para el mantenimiento o
          cumplimiento de una relación jurídica entre el responsable y el
          titular.
        </li>
      </ul>
      <h4>Modificaciones</h4>
      <p>
        En caso de que se requiera alguna modificación a lo estipulado en el
        presente Aviso de Privacidad, WallGo se obliga a hacer del conocimiento
        los cambios que en su caso se requieran, por cualquier medio, incluidos
        los electrónicos, previo aviso que se le de a usted para que se
        manifieste por su parte, lo que a su derecho convenga, ya que de no
        recibir negativa expresa y por escrito de su parte, o bien, respuesta
        alguna, se entenderá que usted acepta de conformidad los cambios
        realizados.
      </p>
      <h4>Consentimiento del Titular</h4>
      <p>
        El Titular de la Información reconoce y acepta que en caso de que este
        “Aviso de Privacidad” esté disponible a través de una página electrónica
        (sitio web, página de Internet o similar) o algún otro dispositivo
        electrónico, al hacer clic en “aceptar” o de cualquier otra forma seguir
        navegando en el sitio, o bien al proporcionar sus Datos a través del
        mismo o a través de cualquier medio electrónico (correo electrónico,
        etc.), constituye una manifestación de su consentimiento para que WallGo
        realice el tratamiento de sus Datos, de conformidad con este Aviso de
        Privacidad.
      </p>
      <p>
        Asimismo, de igual manera manifiesta que en caso de que este “Aviso de
        Privacidad” esté disponible por escrito, su firma, rúbrica, nombre o
        huella o bien al proporcionar sus Datos, constituye una manifestación de
        su consentimiento para que WallGo realice el tratamiento de sus datos,
        de conformidad con este “Aviso de Privacidad”.
      </p>
      <h4>Notificación de cambios del aviso</h4>
      <p>
        Si WallGo cambia este aviso de privacidad, dichos cambios aparecerán en
        el sitio web www.wallgoapp.com
      </p>
      <p>
        Si usted tiene alguna pregunta o desea ejercer algún derecho relacionado
        con esta declaración de privacidad, por favor comuníquese con WallGo y/o
        sus empresas filiales a la siguiente dirección: hola@wallgoapp.com
      </p>
      <p>
        Este aviso de privacidad ha sido creado en base a las reglas expedidas
        por la Ley Federal de Protección de Datos Personales en Posesión de los
        Particulares con fundamento en los artículos 16, 17 y 36 de la Ley y
        corresponsales de su Reglamento.
      </p>
    </Page>
  );
};
export default Privacy;
