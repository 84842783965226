import React from "react";
import { combineComponents } from "./context";
import { PurchasesProvider } from "./context/PurchasesContext";
import { CapacitorProvider } from "./context/CapacitorContext";
import { VehiclesProdiver } from "./context/VehiclesContext";
import { ProductsProvider } from "./context/ProductsContext";
import { ModalProvider } from "./context/ModalContext";
import { UserProvider } from "./context/AuthContext";
import { MenuProvider } from "./context/MenuContext";
import { setupIonicReact } from "@ionic/react";
import "@ionic/react/css/core.css";
import Main from "./Main";
import { PaymentSourcesProvider } from "./context/PaymentSourceContext";
import { MerchantProvider } from "./context/MerchantContext";
import { TicketProvider } from "./context/TicketsContext";
import { WalletsProvider } from "./context/WalletsContext";
import { ChargesProvider } from "./context/ChargesContext";
import { FiltersProvider } from "./context/FiltersContext";

setupIonicReact();

const AppContextProvider = combineComponents([
  UserProvider,
  MenuProvider,
  TicketProvider,
  FiltersProvider,
  ChargesProvider,
  WalletsProvider,
  MerchantProvider,
  ProductsProvider,
  VehiclesProdiver,
  CapacitorProvider,
  PurchasesProvider,
  PaymentSourcesProvider,
]);

function App() {
  return (
    <ModalProvider>
      <AppContextProvider>
        <Main />
      </AppContextProvider>
    </ModalProvider>
  );
}

export default App;
