import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { getValue } from "../utils";
import { MenuContext } from "../context/MenuContext";

const EditProfile = () => {
  const { setSelected } = useContext(MenuContext);
  const { user, updateUsuario, setPropertyUser } = useContext(AuthContext);

  useEffect(() => {
    setSelected("Edit Profile");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUsuario(user);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ overflowX: "hidden" }}
      className="container-fluid py-4"
    >
      <div className="card">
        <div className="input-group mb-3">
          <span className="input-group-text" id="name-addon">
            <i className="fa fa-user text-primary"></i>
          </span>
          <input
            type="text"
            placeholder="Name"
            className="form-control"
            value={getValue(user, "name")}
            alia-aria-describedby="name-addon"
            onChange={(e) => setPropertyUser("name", e.target.value)}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="last_name-addon">
            <i className="fa fa-user text-primary"></i>
          </span>
          <input
            type="text"
            placeholder="Last Name"
            className="form-control"
            value={getValue(user, "last_name")}
            alia-aria-describedby="last_name-addon"
            onChange={(e) => setPropertyUser("last_name", e.target.value)}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="phone-addon">
            <i className="fa fa-phone text-primary"></i>
          </span>
          <input
            type="text"
            placeholder="Phone"
            className="form-control"
            value={getValue(user, "phone")}
            alia-aria-describedby="phone-addon"
            onChange={(e) => setPropertyUser("phone", e.target.value)}
          />
        </div>
        <div className="btn btn-primary w-100 mt-3 mb-4">Save Changes</div>
        <Link to="/profile" className="text-muted btn btn-sm w-100">
          Cancel
        </Link>
      </div>
    </form>
  );
};

export default EditProfile;
