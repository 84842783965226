import React from "react";
import mastercard from "../../assets/images/payment-method-mc.png";
import visa from "../../assets/images/payment-method-visa.png";
import amex from "../../assets/images/payment-method-amex.png";

const ConfirmDeletePaymentSource = ({
  payment_source,
  onSuccess,
  onCancel,
}) => {
  const getSrc = () => {
    return payment_source.card_type === "mastercard"
      ? mastercard
      : payment_source.card_type === "visa"
      ? visa
      : amex;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onSuccess(payment_source.payment_source_id);
  };
  return (
    <div>
      <p>¿Estás seguro que deseas eliminar este método de pago? </p>
      <div className="card mb-3">
        <div className="row align-items-center">
          <div className="col col-md-4">
            <img src={getSrc()} className="card-type" alt="card type" />
          </div>
          <div className="col col-md-4 capitalize">
            {payment_source.card_type}
          </div>
          <div className="col col-md-4">
            {"**** "}
            {payment_source.last_digits}
          </div>
        </div>
      </div>

      <p>Esta acción NO puede deshacerse.</p>
      <button
        type="submit"
        onClick={handleSubmit}
        className="btn btn-danger w-100 mb-4"
      >
        Eliminar
      </button>
      <button
        type="button"
        onClick={onCancel}
        className="btn btn-link text-secondary w-100"
      >
        Cancelar
      </button>
    </div>
  );
};

export default ConfirmDeletePaymentSource;
