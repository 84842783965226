import moment from "moment";
import React from "react";
import { formatMonto } from "../../utils";
import TicketTimeBadge from "./TicketTimeBadge";

const TicketCard = ({ ticket }) => {
  const renderMerchant = () => {
    if (ticket.merchant && ticket.merchant !== null) {
      return ticket.merchant.name;
    }
  };

  const renderStartTime = () => {
    return (
      <p className="mb-0 small text-muted">
        {moment(ticket.createdAt).format("DD MMM YYYY HH:mm")}
      </p>
    );
  };

  return (
    <div className="ticket card p-3 mb-3">
      <div className="row badge-wrapper align-items-center">
        <div className="col-8 px-2">
          <TicketTimeBadge ticket={ticket} />
          <div className="pt-3">
            <p className="mb-1 bold">{renderMerchant()}</p>
            {renderStartTime()}
          </div>
        </div>
        <div className="col-4 ps-4 text-center ticket-left position-relative">
          <div className="top-border" />
          <p
            className={`mb-2 text-capitalize ${
              ticket.status === "paid"
                ? "text-success bold"
                : ticket.status === "pending"
                ? "text-danger"
                : "text-secondary"
            }`}
          >
            {ticket.status}
          </p>
          <h4
            className={`${
              ticket.status === "paid"
                ? "text-success"
                : ticket.status === "pending"
                ? "text-danger"
                : "text-primary"
            } h3 bold`}
          >
            ${formatMonto(ticket.total)}
          </h4>
          <div className="bottom-border" />
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
