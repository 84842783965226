import { navigate } from "@reach/router";
import { Link } from "@reach/router";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { validateEmail, S3_ENDPOINT } from "../utils";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { correo, user, signUp } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (correo !== null) {
      setEmail(correo);
    }
  }, [correo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      return alert("El correo electrónico no es válido.");
    }
    if (String(phone).length !== 10) {
      return alert("El número de teléfono debe tener 10 dígitos.");
    }
    if (confirmPassword !== password) {
      return alert("Las contraseñas no coinciden");
    }
    signUp(name, email, password, phone);
  };
  return (
    <div className="container py-3">
      <img
        src={`${S3_ENDPOINT}/logo.png`}
        className="m-auto d-block"
        style={{ width: 200 }}
        alt="logo"
      />
      <form className="card shadow-sm" onSubmit={handleSubmit}>
        <label className="d-block mb-2">Name</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <i className="fa fa-user text-primary"></i>
          </span>
          <input
            type="text"
            value={name}
            placeholder="Name"
            className="form-control"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <label className="d-block mb-2">Email</label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="email-addon">
            <i className="fa fa-envelope text-primary"></i>
          </span>
          <input
            type="text"
            value={email}
            placeholder="Email"
            className="form-control"
            alia-aria-describedby="email-addon"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <label className="d-block mb-2">Password</label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="password-addon">
            <i className="fa fa-lock text-primary"></i>
          </span>
          <input
            value={password}
            placeholder="Password"
            className="form-control"
            alia-aria-describedby="password-addon"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="input-group-text">
            <button
              type="button"
              className="btn px-0 py-0"
              onClick={() => setShowPassword(!showPassword)}
            >
              <i className={`fas fa-eye${showPassword ? "" : "-slash"}`}></i>
            </button>
          </span>
        </div>
        <label className="d-block mb-2">Confirm Password</label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="password-addon">
            <i className="fa fa-lock text-primary"></i>
          </span>
          <input
            value={confirmPassword}
            className="form-control"
            placeholder="Confirm Password"
            alia-aria-describedby="password-addon"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span className="input-group-text">
            <button
              type="button"
              className="btn px-0 py-0"
              onClick={() => setShowPassword(!showPassword)}
            >
              <i className={`fas fa-eye${showPassword ? "" : "-slash"}`}></i>
            </button>
          </span>
        </div>
        <label className="d-block mb-2">Phone</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <i className="fa fa-phone text-primary"></i>
          </span>
          <input
            type="text"
            value={phone}
            placeholder="Phone"
            className="form-control"
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="container-fluid px-0 mb-3">
          <button className="btn btn-primary w-100 d-block m-auto my-4">
            Create An Account
          </button>
        </div>
        <div className="row">
          <div className="col-6">
            <Link
              to="/recovery"
              className="text-accent h6 text-center d-block w-100"
            >
              Forgot password?
            </Link>
          </div>
          <div className="col-6">
            <Link
              to="/auth"
              className="text-accent h6 text-center d-block w-100"
            >
              Log In
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
