import React, { createContext, useContext, useReducer } from "react";
import VehiclesReducer from "../reducers/VehiclesReducer";
import VehiclesService from "../services/VehiclesService";
import {
  SET_VEHICLE,
  CREATE_VEHICLE,
  VEHICLES_RECEIVED,
  SET_PROPERTY_VEHICLE,
} from "../types/vehicles";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";

const initialState = {
  vehicles: null,
  vehicle: null,
};

export const VehiclesContext = createContext(initialState);

export const VehiclesProdiver = ({ children }) => {
  const [state, dispatch] = useReducer(VehiclesReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getVehicles = () => {
    VehiclesService.getVehicles().then((res) => {
      const { vehicles } = res.data;
      dispatch({ type: VEHICLES_RECEIVED, payload: vehicles });
    });
  };

  const getSingleVehicle = (vehicle_id) => {
    VehiclesService.getSingleVehicle(vehicle_id).then((res) => {
      const { vehicle } = res.data;
      dispatch({ type: SET_VEHICLE, payload: vehicle });
    });
  };

  const createVehicle = () => {
    dispatch({ type: CREATE_VEHICLE });
  };

  const setPropertyVehicle = (key, value) => {
    dispatch({ type: SET_PROPERTY_VEHICLE, payload: { key, value } });
  };
  const clearSingleVehicle = () => {
    dispatch({ type: SET_VEHICLE, payload: null });
  };

  const setVehicle = (vehicle) => {
    dispatch({ type: SET_VEHICLE, payload: vehicle });
  };

  const postVehicle = (vehicle) => {
    let service = VehiclesService.putVehicle;
    if (isNaN(vehicle.vehicle_id)) {
      service = VehiclesService.postVehicle;
    }
    service(vehicle).then(() => {
      success("Vehículo guardado.");
      navigate("/vehicles");
      getVehicles();
      clearModal();
    });
  };

  return (
    <VehiclesContext.Provider
      value={{
        ...state,
        setVehicle,
        getVehicles,
        postVehicle,
        createVehicle,
        getSingleVehicle,
        setPropertyVehicle,
        clearSingleVehicle,
      }}
    >
      {children}
    </VehiclesContext.Provider>
  );
};
