import React, { useContext } from "react";
import PaymentSourceCard from "./PaymentSourceCard";
import { ModalContext } from "../../context/ModalContext";
import ConfirmDeletePaymentSource from "./ConfirmDeletePaymentSource";
import { WalletsContext } from "../../context/WalletsContext";

const WalletPaymentSourceList = ({ wallet_id, payment_sources }) => {
  const { deleteWalletPaymentSource } = useContext(WalletsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleDelete = (payment_source) => {
    modalComponent(
      "Precaución",
      <ConfirmDeletePaymentSource
        payment_source={payment_source}
        onSuccess={(payment_source_id) =>
          deleteWalletPaymentSource(wallet_id, payment_source_id)
        }
        wallet_id={wallet_id}
        onCancel={clearModal}
      />
    );
  };

  const renderMetodosPago = () => {
    if (Array.isArray(payment_sources)) {
      if (payment_sources.length === 0) {
        return <p>No hay métodos de pago registrados</p>;
      }
      return payment_sources.map((payment_source) => (
        <PaymentSourceCard
          hideCheck
          handleDelete={handleDelete}
          payment_source={payment_source}
          key={payment_source.payment_source_id}
        />
      ));
    }
  };

  return <div className="container-fluid px-0">{renderMetodosPago()}</div>;
};

export default WalletPaymentSourceList;
