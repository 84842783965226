export const parsePeriod = (amount, period) => {
  if (period === "minute") {
    if (amount > 1) return "minutos";
    return "minuto";
  }
  if (period === "hour") {
    if (amount > 1) return "horas";
    return "hora";
  }
  if (period === "day") {
    if (amount > 1) return "días";
    return "día";
  }
  if (period === "week") {
    if (amount > 1) return "semanas";
    return "semana";
  }
  if (period === "month") {
    if (amount > 1) return "meses";
    return "mes";
  }
  if (period === "year") {
    if (amount > 1) return "años";
    return "año";
  }
};
