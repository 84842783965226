import moment from "moment";
import React from "react";

const TicketTimeBadge = ({ ticket }) => {
  const getElapsedTime = () => {
    let start_time = moment();
    if (ticket.end_date !== null) {
      start_time = moment(ticket.end_date);
    }
    return start_time.diff(ticket.createdAt, "hours");
  };

  return (
    <div
      className={`badge w-75 badge-pill bg-light ${
        ticket.end_date === null ? "ps-4" : "px-4"
      }`}
    >
      <div className="row align-items-center">
        <div
          className={`${
            ticket.end_date === null
              ? "text-end col-4 col-md-4 px-0"
              : "text-center col-12"
          }`}
        >
          <span className="d-none fw-light" style={{ fontSize: 10 }}>
            time elapsed
          </span>
          <span className="time">{getElapsedTime()} hrs</span>
        </div>
        {ticket.end_date === null && (
          <div className="col-8 col-md-8 px-2 text-center">
            <span className="badge w-100 py-2 mb-0 px-3 badge-pill bg-primary">
              <span className="d-block mb-1" style={{ fontSize: 8 }}>
                next bill on
              </span>
              <span className="time">
                <i className="fa fa-clock me-2"></i>{" "}
                {moment().endOf("hour").diff(moment(), "hours")}:
                {moment().endOf("hour").diff(moment(), "minutes")}
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketTimeBadge;
