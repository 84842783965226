import React, { createContext, useReducer } from "react";
import PurchasesReducer from "../reducers/PurchasesReducer";
import InvoicesService from "../services/InvoicesService";
import PurchasesService from "../services/PurchasesService";
import {
  PURCHASES_RECIBIDAS,
  INVOICES_RECIBIDOS,
  SET_PURCHASE,
  SET_INVOICE,
} from "../types/purchases";

const initialState = {
  purchases: null,
  purchase: null,
  invoices: null,
};

export const PurchasesContext = createContext(initialState);

export const PurchasesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PurchasesReducer, initialState);

  const getPurchases = () => {
    PurchasesService.getPurchases().then((res) => {
      const { purchases } = res.data;
      dispatch({ type: PURCHASES_RECIBIDAS, payload: purchases });
    });
  };

  const getInvoices = () => {
    InvoicesService.getInvoices().then((res) => {
      const { invoices } = res.data;
      dispatch({ type: INVOICES_RECIBIDOS, payload: invoices });
    });
  };

  const getPurchase = (purchase_id) => {
    PurchasesService.getPurchase(purchase_id).then((res) => {
      const { purchase } = res.data;
      dispatch({ type: SET_PURCHASE, payload: purchase });
    });
  };

  const getInvoice = (invoice_id) => {
    InvoicesService.getSingleInvoice(invoice_id).then((res) => {
      const { invoice } = res.data;
      dispatch({ type: SET_INVOICE, payload: invoice });
    });
  };

  return (
    <PurchasesContext.Provider
      value={{
        ...state,
        getInvoice,
        getInvoices,
        getPurchase,
        getPurchases,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
};
