import React, { useContext, useEffect } from "react";
import { ReactComponent as Bars } from "../../assets/icons/bars.svg";
import { CapacitorContext } from "../../context/CapacitorContext";
import { AuthContext } from "../../context/AuthContext";
import { MenuContext } from "../../context/MenuContext";
import { hasNotch } from "../../utils";
import { Link } from "@reach/router";
import Sidebar from "./Sidebar";

const Navbar = ({ clear }) => {
  const { device, platform, setupStatusBar } = useContext(CapacitorContext);
  const { mode, selected } = useContext(MenuContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (platform !== null && platform !== "web") {
      setupStatusBar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  const getPaddingTop = () => {
    return platform === "ios" && hasNotch(device)
      ? 60
      : platform !== "web"
      ? 10
      : 10;
  };

  return (
    <nav
      id="navbar"
      className={`navbar align-items-center pb-2 fixed-top ${
        mode === "light" ? "navbar-dark" : "navbar-light"
      }`}
      style={{
        paddingTop: getPaddingTop(),
      }}
    >
      <div className="container px-0">
        <div className="row align-items-center w-100 mx-0">
          <div className="col-3">
            <button
              className="btn btn-sm p-2 px-0"
              style={{ visibility: user !== null ? "visible" : "hidden" }}
              data-bs-toggle="offcanvas"
              data-bs-target="#staticBackdrop"
              aria-controls="staticBackdrop"
            >
              <Bars
                fill={clear || mode === "dark" ? "#fff" : "#032251"}
                className="w-75"
              />
            </button>
          </div>
          <div className="col-6 text-center">
            <Link
              to="/"
              className={`no-decoration text-center ${
                mode === "light" ? "text-dark" : "text-white"
              }`}
            >
              <span className={`${clear ? "text-white" : ""}`}>{selected}</span>
            </Link>
          </div>
          <div className="col-3 text-end">
            <Link
              to="/qrcode"
              className={`btn p-2 px-0 ${
                clear || mode === "dark" ? "text-white" : ""
              }`}
            >
              <i className="fa-solid fa-qrcode"></i>
            </Link>
          </div>
        </div>
        <Sidebar />
      </div>
    </nav>
  );
};

export default Navbar;
