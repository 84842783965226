import React, { useContext, useEffect } from "react";
import { MenuContext } from "../context/MenuContext";
import { AuthContext } from "../context/AuthContext";
import UserData from "../components/user/UserData";
import ReactSwitch from "react-switch";

const Profile = () => {
  const { user, updateUser } = useContext(AuthContext);
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected("My Profile");
  }, []);

  const handleNotifications = (checked) => {
    updateUser({ ...user, notifications: checked });
  };

  return (
    <div className="container-fluid pt-4">
      <UserData user={user} />
      <div className="card py-2">
        <div className="row align-items-center py-3 border-bottom">
          <div className="col-8">Reset Password</div>
          <div className="col-4 text-end">
            <i className="fa fa-chevron-right"></i>
          </div>
        </div>
        <div className="row align-items-center py-3">
          <div className="col-8">Notifications</div>
          <div className="col-4 text-end">
            <ReactSwitch
              checked={user.notifications}
              onChange={handleNotifications}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
