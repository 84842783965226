import React, { useContext } from "react";
import UserCard from "./UserCard";
import { ModalContext } from "../../context/ModalContext";
import { WalletsContext } from "../../context/WalletsContext";
import { AuthContext } from "../../context/AuthContext";

const UserWalletList = ({ wallet_id, users }) => {
  const { deleteUserWallet } = useContext(WalletsContext);
  const { modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const currentUser = user;

  const handleDeleteUser = (user) => {
    modalComponent(
      "Eliminar Usuario",
      <div>
        <p>¿Estás seguro que deseas eliminar al usuario {user.email}?</p>
        <button
          className="btn btn-danger w-100 my-3"
          onClick={() => deleteUserWallet(wallet_id, user.user_id)}
        >
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </div>
    );
  };

  const renderUsers = () => {
    if (Array.isArray(users)) {
      if (users.length === 0) return <p>No users registered.</p>;
      return users.map((user) => (
        <UserCard
          key={user.user_id}
          user={user.user}
          currentUser={currentUser}
          handleDelete={handleDeleteUser}
        >
          <p className="mb-0 text-capitalize">
            {user.role}{" "}
            {user.user.uid === null && (
              <span className="badge mx-2 bg-warning text-dark">Pendiente</span>
            )}
          </p>
        </UserCard>
      ));
    }
  };
  return <div className="container px-0">{renderUsers()}</div>;
};
export default UserWalletList;
