export const googleMapStyles = [
  { elementType: "geometry", stylers: [{ color: "#0f1114" }] },
  {
    elementType: "labels.icon",
    stylers: [{ lightness: -25 }, { saturation: -100 }],
  },
  { elementType: "labels.text.fill", stylers: [{ color: "#787878" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#18191b" }] },
  {
    elementType: "geometry.fill",
    featureType: "administrative",
    stylers: [{ color: "#262626" }],
  },
  {
    elementType: "geometry.stroke",
    featureType: "administrative",
    stylers: [{ color: "#707070" }],
  },
  {
    elementType: "labels.text.stroke",
    featureType: "administrative",
    stylers: [{ color: "#1a1a1a" }],
  },
  {
    elementType: "labels.text.fill",
    featureType: "administrative.country",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [{ visibility: "off" }],
  },
  {
    elementType: "labels.text.fill",
    featureType: "administrative.locality",
    stylers: [{ color: "#bdbdbd" }],
  },
  {
    elementType: "labels.text.fill",
    featureType: "administrative.neighborhood",
    stylers: [{ color: "#565b67" }],
  },
  {
    elementType: "labels.text.stroke",
    featureType: "administrative.neighborhood",
    stylers: [{ color: "#000000" }],
  },
  {
    elementType: "geometry",
    featureType: "landscape.man_made.business_corridor",
    stylers: [{ color: "#231924" }, { visibility: "off" }],
  },
  {
    elementType: "geometry",
    featureType: "landscape.natural.terrain",
    stylers: [{ color: "#0f100f" }],
  },
  {
    elementType: "labels.icon",
    featureType: "poi",
    stylers: [{ color: "#ffffff" }, { lightness: -100 }],
  },
  {
    elementType: "labels.text.fill",
    featureType: "poi",
    stylers: [{ color: "#8f8f8f" }],
  },
  {
    elementType: "labels.text.stroke",
    featureType: "poi",
    stylers: [{ color: "#000000" }],
  },
  {
    elementType: "geometry",
    featureType: "poi.park",
    stylers: [{ color: "#101e1e" }],
  },
  {
    elementType: "geometry",
    featureType: "poi.place_of_worship",
    stylers: [{ visibility: "off" }],
  },
  {
    elementType: "geometry.fill",
    featureType: "road",
    stylers: [{ color: "#292f38" }],
  },
  {
    elementType: "geometry.stroke",
    featureType: "road",
    stylers: [{ color: "#0f1114" }],
  },
  {
    elementType: "labels.text.fill",
    featureType: "road",
    stylers: [{ color: "#5d6674" }],
  },
  {
    elementType: "labels.text.stroke",
    featureType: "road",
    stylers: [{ color: "#000000" }],
  },
  {
    elementType: "geometry.fill",
    featureType: "road.highway",
    stylers: [{ color: "#293b51" }],
  },
  {
    elementType: "geometry.stroke",
    featureType: "road.highway",
    stylers: [{ color: "#0b0b0f" }],
  },
  {
    elementType: "labels.text.fill",
    featureType: "road.local",
    stylers: [{ color: "#5d6674" }],
  },
  {
    elementType: "labels.icon",
    featureType: "transit",
    stylers: [{ lightness: -50 }],
  },
  {
    elementType: "labels.text.fill",
    featureType: "transit",
    stylers: [{ color: "#666666" }],
  },
  {
    elementType: "geometry",
    featureType: "water",
    stylers: [{ color: "#000000" }],
  },
  {
    elementType: "labels.text.fill",
    featureType: "water",
    stylers: [{ color: "#354245" }],
  },
];
