import { IonModal } from "@ionic/react";
import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const MobileModal = ({ children }) => {
  const { showModal, title, onCancel, content, component } =
    useContext(ModalContext);
  return (
    <IonModal
      handleBehavior="cycle"
      isOpen={showModal}
      initialBreakpoint={0.3}
      breakpoints={[0.3, 0.75, 0.95]}
      onDidDismiss={onCancel}
    >
      {title && (
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
        </div>
      )}
      <div className="modal-body">
        {content}
        {component}
        {children}
      </div>
    </IonModal>
  );
};

export default MobileModal;
