import React, { createContext, useReducer } from "react";
import { SET_MODE, SET_SELECTED } from "../types/menu";
import MenuReducer from "../reducers/MenuReducer";

const initialState = {
  mode: "light",
  menus: [
    {
      name: "Menú",
      items: [
        {
          name: "My Wallets",
          link: "/wallets",
          icon: "w-wallet",
        },
        {
          name: "My Vehicles",
          link: "/vehicles",
          icon: "w-vehicles",
        },
        {
          name: "My Tickets",
          link: "/tickets",
          icon: "w-ticket",
        },
        {
          name: "My Payments",
          link: "/charges",
          icon: "w-history",
        },
        {
          name: "Parking Spots",
          link: "/merchants",
          icon: "w-map",
        },
        {
          name: "Profile",
          link: "/profile",
          icon: "user",
          isRegular: true,
        },
      ],
    },
  ],
  selected: null,
};

export const MenuContext = createContext(initialState);

export const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MenuReducer, initialState);

  const setSelected = (selected) => {
    dispatch({ type: SET_SELECTED, payload: selected });
  };

  const setMode = (mode) => {
    dispatch({ type: SET_MODE, payload: mode });
  };

  return (
    <MenuContext.Provider value={{ ...state, setMode, setSelected }}>
      {children}
    </MenuContext.Provider>
  );
};
